import { Component, OnInit } from '@angular/core';
import { ReskontoService } from '../../services/reskontro.service';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import nb from '@angular/common/locales/nb';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-reskontro',
  templateUrl: './reskontro.component.html',
  styleUrls: ['./reskontro.component.css']
})
export class ReskontroComponent implements OnInit {

  constructor(private reskonto: ReskontoService, private auth: AuthService, private sanitizer: DomSanitizer) { }

  Url: any;
  PogoId: string = "";
  Reskonto:any[]=[];
  showSpinner:boolean=false;
  ngOnInit() {
    registerLocaleData( nb );
    var resp = this.auth.account;
    if(resp!=null&&resp.Name!=null)
    {
          this.showSpinner = true;
          this.reskonto.getPOCustomerCode(resp.PogoId).subscribe(x => {
            // console.log(x);
              if(x!=null&&x.data!=null)
              {
                  
                        this.reskonto.getPOCustomerLedger(x.data.code).subscribe(list=>{
                          // console.log(list);
                          if(list!=null&&list.data!=null&&list.data.length>0)
                          {
                          list.data.forEach(resp => {
                            
                            let date = new Date(resp.dueDate.toString());
                            
                            this.Reskonto.push({Id:resp.voucherNo,Documentno:resp.documentNo,VoucherType:(resp.voucherType==4?"Faktura":resp.voucherType==9?"Innbetaling":(resp.voucherType==2||resp.voucherType==5)?"Kreditnota":"Kreditnota"),DueDate:date.toLocaleDateString(),Amount:resp.amount,HasVoucher:resp.hasVoucherDocumentation});
                              
                          });
                          this.Reskonto.sort((a,b)=>{return <any>new Date(b.DueDate)-<any>new Date(a.DueDate)});
                        }
                          this.showSpinner= false;
                        },error=>{
                          console.log("Error in getPOCustomerLedger");
                          this.showSpinner= false;
                        });
                  
              }
              else{
                this.showSpinner= false;
              }
        
          },error=>{
            console.log("Error in getPOCustomerByName");
            this.showSpinner= false;
          });     
    }
    else
    {
      this.showSpinner = true;
      this.auth.getAccountInfo().subscribe(x => {
      
         if (!x) {
         } else {
            this.auth.account = x;
            var resp = this.auth.account;
              this.reskonto.getPOCustomerCode(resp.PogoId).subscribe(x => {
                // console.log(x);
                  if(x!=null&&x.data!=null)
                  {

                      
                            this.reskonto.getPOCustomerLedger(x.data.code).subscribe(list=>{
                              // console.log(list);
                              if(list!=null&&list.data!=null&&list.data.length>0)
                              {
                              list.data.forEach(resp => {
                                
                                let date = new Date(resp.dueDate.toString());
                                
                                this.Reskonto.push({Id:resp.voucherNo,Documentno:resp.documentNo,VoucherType:(resp.voucherType==4?"Faktura":resp.voucherType==9?"Innbetaling":(resp.voucherType==2||resp.voucherType==5)?"Kreditnota":"Kreditnota"),DueDate:date.toLocaleDateString(),Amount:resp.amount,HasVoucher:resp.hasVoucherDocumentation});
                                  
                              });
                              this.Reskonto.sort((a,b)=>{return <any>new Date(b.DueDate)-<any>new Date(a.DueDate)});
                            }
                              this.showSpinner= false;
                            },error=>{
                              console.log("Error in getPOCustomerLedger");
                              this.showSpinner= false;
                            });
                      
                  }
                  else{
                    this.showSpinner= false;
                  }
            
              },error=>{
                console.log("Error in getPOCustomerByName");
                this.showSpinner= false;
              });     
         }
        },error=>{
          console.log("Error in getAccountInfo");
          this.showSpinner= false;
        });
    }
   
  }
  downloadVoucher(id)
  {
    this.reskonto.getVouvherPdf(id).subscribe(x => {
      saveAs(x,"Faktura.pdf");
    },error=>{
      console.log("Error")
    });
  }

}
