import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Address } from '../../models/address';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-postadresseform',
  templateUrl: './postadresseform.component.html',
  styleUrls: ['./postadresseform.component.css']
})
export class PostadresseformComponent implements OnInit {

  @Input() MailAddress: Address;
  @Input() AccountForm: FormGroup;
  @Input() AddressType: string;
  @Output() adressChanged = new EventEmitter<Address>();

  constructor() { }

  ngOnInit() {
    if (this.MailAddress == null) {
      this.MailAddress = { AddressLine1: "", AddressLine2: "", City: "", Country: "", Postalcode: "" };
    }

   // console.log(this.AccountForm);
   // console.log("over");
  }

  sendAddress() {
   // console.log(this.MailAddress);
   // console.log("adress change?");
    this.adressChanged.emit(this.MailAddress);
  }



}
