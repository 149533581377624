import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router"
import { CRMAccount } from '../models/CRMAccount';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {

  loggedIn: boolean;
  loginButtonName: string = "Logg inn";
  //LogoName: string = "";

  UserEmail: string;
  account: CRMAccount = {};
  IsAuthenticated:boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver, private auth: AuthService, private router: Router) { }
 ngOnInit() {
    
    this.loggedIn = this.auth.isLoggedIn();
    this.UserEmail = this.auth.getUserEmail();
    //this.isHandset$.subscribe(isHandset => console.log(isHandset));
    //console.log(this.loggedIn + " Constructor");
    //console.log(this.authService.getUserEmail() + " : this is the users email");
    /* this is extra call which is no use  (need a changes to do it in this way and this way  reduces lot more time)
    if (this.loggedIn) {
      
      this.contactService.getContact().subscribe(x => {
       // console.log("found contactinfo");
        this.authService.contact = x;
       // console.log(this.authService.contact);
        this.authService.getAccountInfo().subscribe(x => {
          this.account = x;
          this.authService.account = x;
        });
      }, error => {
         // console.log("dident find contact info");
      });
      
      //this.authService.AccountMail = this.authService.getUserEmail();
     // console.log(this.authService.AccountMail+" : AccountEmail!");
     // console.log("getting account info");
    }
    */
  }
  logout() {


    this.auth.logout();
    //this.account = {};
    //this.authService.logout().subscribe(x => {
    //  //this needs change in service!
    //}, error => {

    //});
  }
  
  async login() {
    
    this.auth.login();   
    //this.authService.login().subscribe(loginInfo => {

    //  this.loggedIn = true;    
    //  this.account = loginInfo;//set values in this component.


    //  this.authService.account = loginInfo;// set values in Service.

    //  console.log(loginInfo);
    //}, error => {
    //  console.log(error);
    //  });


    //this.contactService.getContact().subscribe(x => {
    //  this.authService.contact = x;
    //  console.log(this.authService.contact);
    //});

    //this.LogoName = this.loggedIn == true ? "Garn og Ull AS" : "";
    //this.router.navigate(['/']);
  }
  checkUser():boolean {
    this.IsAuthenticated= false;
    var isauth=localStorage.getItem("IsAuthenticated");
    var accountinfo = JSON.parse(localStorage.getItem("accountinfo"));
    if(accountinfo!=null)
    {
      this.account=accountinfo;
    }
    if(isauth!=null&&isauth=="true")
    {
      this.IsAuthenticated=true;
    }
    return this.auth.getUser() != null;
  }


  Registration() {
    this.router.navigate(['/Registrer']);
  }
}
