// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  /*production: true,
  Api: "https://gs1apiprod.azurewebsites.net/api",
  redirect: "https://gs1portalprod.azurewebsites.net/Home",
  //redirect: "https://gs1testportal.azurewebsites.net/Home",
  //Api: "https://gs1testapi.azurewebsites.net/api",
  MineGtin: "https://register.gs1.no/licences"
*/
production: true, 
 Api: "https://gs1apiprod.azurewebsites.net/api",
  redirect: "https://gs1portalprod.azurewebsites.net/Home",
  MineGtin:"https://register.gs1.no/licences"
};
