import { Component, OnInit } from '@angular/core';
import { RouterOutlet, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { slider } from './route-animations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import * as Msal from 'msal';
import { AuthService } from './services/auth.service';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slider
  ]
})


export class AppComponent implements OnInit {
  title = 'app';
  test = window.location.href;
  bol: boolean = true;
  
   
  

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private authService: AuthService) {
    
    this.router.events.subscribe(event=>
      {
        if(event instanceof NavigationEnd){
          gtag('config','UA-133940649-2',{
            'page_path':event.urlAfterRedirects
          });
        }
      })
    if (window.location.href.includes('AADB2C90091')) {
      localStorage.removeItem("error");
      this.router.navigate(['/']);
    }

    if (window.location.href.includes('client_info')) {
      localStorage.removeItem("error");
      this.authService.getAuthenticationToken();
      this.router.navigate(['/']);
    }

    if (window.location.href.includes('AADB2C90118')) {
      localStorage.setItem("error", window.location.href);
      this.router.navigate(['/']);
    }
    console.log(localStorage.getItem("error"));
    if (localStorage.getItem("error") != null && localStorage.getItem("error") != "null" ) {
    
      let msalConfigReset = {
        auth: {
          clientId: "c1f6f6a4-00e4-46c1-879c-c414c60fd357",
          authority: "https://gs1norwayb2c.b2clogin.com/tfp/" + "gs1norwayb2c.onmicrosoft.com" + "/" + "B2C_1_ResetPassword",
          validateAuthority:false,
          // navigateToLoginRequestUrl:false
        },
      };

     let clientApplicationReset = new Msal.UserAgentApplication(msalConfigReset);

      clientApplicationReset.handleRedirectCallback((error, response) => {
        // console.log("test");
      });
      

      clientApplicationReset.loginRedirect({ scopes: ["https://gs1norwayb2c.onmicrosoft.com/accessapi/user_impersonation"] })
    }
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  ngOnInit() {
    
   
  }
  prepareRoute(outlet:RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


}


