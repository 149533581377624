import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CRMAccount, Departments } from '../models/crmAccount'
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CrmAccountService {

  constructor(private httpClient: HttpClient, private User: AuthService) { }


  getAccount(AccountId) {
    //return this.http.get<any>();

    return this.httpClient.get<CRMAccount>(`${environment.Api}/User/get/${AccountId}/`);
  
  }
  checkMGVsibility(AccountId){
    return this.httpClient.get<any>(`${environment.Api}/User/mingtinshow/${AccountId}/`);
  }
  updateAccount(account: CRMAccount) {
  //  console.log(account.InvoiceType + " <-- InvoiceType");

    //return this.http.get<any>();
    return this.httpClient.put<CRMAccount>(`${environment.Api}/User`, account);

  }

  getAccountWithOrgNr(OrgNr) {
    return this.httpClient.get<CRMAccount>(`${environment.Api}/user/getbyorgnumber/${OrgNr}`);
  }

  CheckAccountWithOrgNr(OrgNr) {
    return this.httpClient.get<CRMAccount>(`${environment.Api}/user/exists/${OrgNr}`);
  }


  getIndustryCodes() {
    return this.httpClient.get<Departments[]>(`${environment.Api}/user/getindustrycodes`);
  }

  PostAccount(account: CRMAccount) {
    return this.httpClient.put<CRMAccount>(`${environment.Api}/User`, account);
  }

  
}
