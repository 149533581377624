
import { Injectable } from '@angular/core';
import { CRMAccount } from '../models/CRMAccount';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../models/contact';
import { Membership as Avtale } from '../models/membership';
import * as Msal  from 'msal';
import { error } from 'protractor';
import { ContactService } from './contact.service';
import { async } from 'q';
import { ucs2 } from 'punycode';
import { CrmAccountService } from './crm-account.service';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //isLoggedIn: boolean = false;
  AccountMail: string = "";//MyTestMail@Test.com
  account: CRMAccount = {};
  contact: Contact = {};
  Avtale: Avtale = {ProductNumber: ''};
  Avtaler: Avtale[];
  Contacts: Contact[];
  HoldUser: boolean = true;

  constructor(private httpClient: HttpClient) {
    this.clientApplication.handleRedirectCallback((error, response) => {
    
    });
  }

 
  loginOauth() {

  }

 

  async login() {
   
    await this.authenticate();
    let user = this.getUser();
    if (user != null) {
      if (user.idToken['emails'] != null && user.idToken['emails'][0] != "") {
        return user.idToken['emails'][0];
      } else if (user.userName != null) {
        return user.userName;
      } else {
        
      }
    }
    
    
    

    //test if user exists or not


  }
  getAccountInfo() {
    
    if (this.getUserEmail() != null && this.getUserEmail() != "") {
     
      this.AccountMail = this.getUserEmail();
      return this.httpClient.get<CRMAccount>(`${environment.Api}/user/logininfo/${this.AccountMail}/`);
      
 
    } else {
     
    }
    
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //https://gs1norwayb2c.b2clogin.com/gs1norwayb2c.onmicrosoft.com/oauth2/authresp

  B2CTodoAccessTokenKey = "ColAccess";
  tenantConfig: { tenant: any, clientId: any, signInPolicy: any, signUpPolicy: any, resetPassword: any, redirectUri: any, b2cScopes:any} = {
    tenant: "gs1norwayb2c.onmicrosoft.com",
    clientId: "c1f6f6a4-00e4-46c1-879c-c414c60fd357",
    signInPolicy: "B2C_1_GS1_Norway",
    signUpPolicy: "B2C_1_GS1_Norway",
    resetPassword:"B2C_1_ResetPassword",
    redirectUri: environment.redirect,
    b2cScopes: ["https://gs1norwayb2c.onmicrosoft.com/accessapi/user_impersonation"],
    
  };

  
  msalConfig = {
    auth: {
      clientId: this.tenantConfig.clientId,
      authority: "https://gs1norwayb2c.b2clogin.com/tfp/" + this.tenantConfig.tenant + "/" + this.tenantConfig.signInPolicy,
      validateAuthority:false,
      // navigateToLoginRequestUrl:false
    },
    
  };

  msalConfigReset = {
    auth: {
      clientId: this.tenantConfig.clientId,
      authority: "https://gs1norwayb2c.b2clogin.com/tfp/" + this.tenantConfig.tenant + "/" + this.tenantConfig.resetPassword,
      validateAuthority:false,
      // navigateToLoginRequestUrl:false
    },

  };

  clientApplication = new Msal.UserAgentApplication(this.msalConfig);

  clientApplicationReset = new Msal.UserAgentApplication(this.msalConfigReset);


  getUser() {
   
      return this.clientApplication.getAccount()
    
    
  }

  getUserEmail(): string {
    let user;
   
      user = this.clientApplication.getAccount();
    


    if (user != null) {
      if (user.idToken['emails'] != null && user.idToken['emails'][0] != "") {
       return user.idToken['emails'][0];
      } else {
       return user.userName;
      }
    }

    return null;
  }
  
  isLoggedIn(): any {

      return this.clientApplication.getAccount() != null && localStorage.getItem("Valid") == "true"; 
       

   
  }

  logout(): void {
    sessionStorage.removeItem(this.getUserEmail());
    sessionStorage.removeItem("ColAccess");
    localStorage.removeItem("Valid");
    localStorage.removeItem("ColAccess");
    localStorage.removeItem("userName");
    localStorage.removeItem("listofMovedAgreement");
    localStorage.removeItem("accountinfo");
    localStorage.removeItem("MinGTIN");  

      this.clientApplication.logout();
    


    //.HoldUser = true;
  }



  saveAccessTokenToCache(accessToken: any) {
    sessionStorage.setItem(this.B2CTodoAccessTokenKey, JSON.stringify(accessToken));
    localStorage.setItem(this.B2CTodoAccessTokenKey, JSON.stringify(accessToken));
    
   
    let test = JSON.parse(localStorage.getItem(this.B2CTodoAccessTokenKey));
    this.ValidateUser()

    
    //this.HoldUser = false;
  };
    async ValidateUser()
  {
    return await this.httpClient.get<Contact>(`${environment.Api}/checkuser`).pipe().toPromise().then(async ()=>{
      localStorage.setItem("Valid", "true");
    },error=>{
      console.log("User is not valid by checkuser",error);
    });
   
    
  }

  public async authenticate() {
    var _this = this;
    
    

    await this.clientApplication.loginRedirect({ scopes: this.tenantConfig.b2cScopes});
    
  }


public  getAuthenticationToken(): Promise<any> {
    
    return  this.clientApplication.acquireTokenSilent({ scopes: this.tenantConfig.b2cScopes })
      .then(token => {
         this.saveAccessTokenToCache(token);
        
        return token;
      }).catch(error => {
        this.logout();
        return this.clientApplication.acquireTokenSilent({ scopes: this.tenantConfig.b2cScopes })
          .then(token => {
            // console.log(token);
            return Promise.resolve(token);
          }).catch(innererror => {
            console.error('Could not retrieve token from popup.', innererror);
            
            return Promise.resolve('');
          });
      });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




}
