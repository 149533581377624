import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common'; 
import {GoogleAnalyticsService} from './google-analytics.service';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';

import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { MatFormFieldModule, MatMenuModule, MatIconModule, MatButtonModule, MatToolbarModule, MatSidenavModule, MatListModule, MatRadioModule, MatInputModule, MatSelectModule, MatStepperModule } from '@angular/material';

import { InputTextarea } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenubarModule, } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DataTableModule } from 'primeng/datatable';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';


import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { InfoboxComponent } from './Components/infobox/infobox.component';
import { FirmaopplysningerComponent } from './Components/firmaopplysninger/firmaopplysninger.component';
import { KontakterComponent } from './Components/kontakter/kontakter.component';
import { AbonnementComponent } from './Components/abonnement/abonnement.component';
import { ReskontroComponent } from './Components/reskontro/reskontro.component';
import { SamtykkerComponent } from './Components/samtykker/samtykker.component';
import { PostadresseformComponent } from './Components/postadresseform/postadresseform.component';

import { LoginGuardGuard } from './LoginGuard/login-guard.guard';
import { RegistrerComponent } from './Components/registrer/registrer.component';
import { InterceptorService } from './services/interceptor.service';

import { DatePipe } from '@angular/common';



@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    FetchDataComponent,
    MainNavComponent,
    InfoboxComponent,
    FirmaopplysningerComponent,
    KontakterComponent,
    AbonnementComponent,
    ReskontroComponent,
    SamtykkerComponent,
    PostadresseformComponent,
    RegistrerComponent,
    
    


  ],
  
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MenubarModule,
    ButtonModule,
    InputSwitchModule,
    TableModule,
    DropdownModule,
    MatInputModule,
    MatRadioModule,
    RadioButtonModule,
    CheckboxModule,
    CommonModule,
    DataTableModule,
    PaginatorModule,
    DialogModule,
    ReactiveFormsModule,
    ToastModule,
    ProgressSpinnerModule,
    SplitButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatStepperModule,
    SelectButtonModule,
    MultiSelectModule,
    PanelModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    

    RouterModule.forRoot([
      { path: '', redirectTo: 'Home', pathMatch: 'full' },
      { path: 'Home', component: HomeComponent, data: { animation: 'farLeft' }},
      { path: 'Registrer', component: RegistrerComponent },
      //{ path: 'Samtykker', component: SamtykkerComponent, data: { animation: 'farRight' }, canActivate: [LoginGuardGuard]},      
      { path: 'Firmaopplysninger', component: FirmaopplysningerComponent, data: { animation: 'innerLeft' }, canActivate: [LoginGuardGuard] },
      { path: 'Kontakter', component: KontakterComponent, data: { animation: 'middleLeft' }, canActivate: [LoginGuardGuard] },
      { path: 'Abonnement', component: AbonnementComponent, data: { animation: 'middleRight' }, canActivate: [LoginGuardGuard] },
      { path: 'Reskontro', component: ReskontroComponent, canActivate: [LoginGuardGuard], data: { animation: 'innerRight' }} ,
    ]),

    LayoutModule,

    MatToolbarModule,

    MatSidenavModule,

    MatListModule
  ],
  providers: [DatePipe, MessageService, ConfirmationService, InputTextarea, { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi:true},GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
