import { MenuItem } from 'primeng/api';
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CrmAccountService } from '../services/crm-account.service';
import {environment} from '../../environments/environment';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
mingtinvisiblie:boolean=false;
showSpinner:boolean=false;
IsAuthenticated:boolean=false;
Imageinfo={"id":1,"type":"GTIN","url":environment.MineGtin,"src":"assets/image/Gtin_barcode.png"};
  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService,private accountservice:CrmAccountService,private messageService:MessageService) { }
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));


  ngOnInit() {
    this.showSpinner=true;
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    this.IsIE = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    //console.log(sessionStorage.getItem("msal.client.info"));
    if (sessionStorage.getItem("msal.client.info") != null && sessionStorage.getItem("msal.client.info") != "") {      
      this.authService.getAuthenticationToken().then(item=>{
       var resp = localStorage.getItem("Valid");
       if(resp!=null||resp)
       {
        if(this.checkUser())
        {
          this.showSpinner=true;
          this.authService.getAccountInfo().subscribe(item=>{
            var accountid=item.Id;
            if(item!=null)
            {
              localStorage.setItem("accountinfo",JSON.stringify(item));
            }
            
            this.IsAuthenticated=item["IsAuthenticated"];
            localStorage.setItem("IsAuthenticated",item["IsAuthenticated"]);
            if(this.IsAuthenticated)
            {
              this.accountservice.checkMGVsibility(accountid).subscribe(item=>{
                this.mingtinvisiblie= item.MinGtinAllowed;
                localStorage.setItem("MinGTIN",item.MinGtinAllowed);        
                this.showSpinner = false;
              }, error => {
                this.showSpinner= false;
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'MingtinVisibility Feilet' })
              });
            }
            else
            {
              this.showSpinner = false;
            }
            
          },error=>{
            this.showSpinner= false;
              this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Account Feilet' })
          });
          
          
        }
        else{
          this.showSpinner= false;
        }
      }
      else{
        this.authService.ValidateUser().then(x=>{
          if(this.checkUser())
        {
          this.showSpinner=true;
          this.authService.getAccountInfo().subscribe(item=>{
            var accountid=item.Id;
            this.IsAuthenticated=item["IsAuthenticated"];
            if(item!=null)
            {
              localStorage.setItem("accountinfo",JSON.stringify(item));
            }
            localStorage.setItem("IsAuthenticated",item["IsAuthenticated"]);
            if(this.IsAuthenticated)
            {
              this.accountservice.checkMGVsibility(accountid).subscribe(item=>{
                this.mingtinvisiblie= item.MinGtinAllowed;   
                localStorage.setItem("MinGTIN",item.MinGtinAllowed);      
                this.showSpinner = false;
              }, error => {
                this.showSpinner= false;
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'MingtinVisibility Feilet' })
              });
            }
            else
            {
              this.showSpinner = false;
            }
            
          },error=>{
            this.showSpinner= false;
              this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Account Feilet' })
          });
          
          
        }
        else{
          this.showSpinner= false;
        }
        })
        
      }
      }).catch((any)=>{
        this.showSpinner= false;
      });
    }
    else{
      this.showSpinner= false;
    }
    
  }

  IsIE: boolean;
  checkUser(): boolean {
    //console.log("logged inn? "+ this.authService.getUser());

    return (this.authService.isLoggedIn());

  }
  loadingLoginInfo() {
      return this.authService.isLoggedIn();
  }

  async login() {

    this.authService.login();

  }


}
