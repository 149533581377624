import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor { 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = JSON.parse(sessionStorage.getItem(this.auth.B2CTodoAccessTokenKey));
    
   // console.log(token);
    //console.log(token['accessToken']);
   // console.log("Token over!");
    if (token != null && token['accessToken'] != null) {

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token['accessToken']}`
        }
      });
    } else {
      request = request.clone({
        setHeaders: {  
        }
      });

    }
    return next.handle(request).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        //console.log(error);
        if (error.status != 404) {
          this.messageService.add({ severity: 'error', summary: 'Feil', detail: error.error != null ? error.error['Message'] != null ? error.error['Message']: 'Noe gikk galt':'Noe gikk galt' });        

        }
        return throwError(error);
      })
    );;
    
  }
   
  constructor(private auth: AuthService, private messageService: MessageService) { }




}
