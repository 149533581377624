import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../../app/services/contact.service';
import { Contact } from '../../models/contact';
//import { brregOrg } from 'src/app/services/contact.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { error } from '@angular/compiler/src/util';
import { AuthService } from '../../services/auth.service';
import { authResponseCallback } from 'msal/lib-commonjs/UserAgentApplication';
import { CRMAccount } from '../../models/CRMAccount';
import { forEach } from '@angular/router/src/utils/collection';
import { MembershipService, Reason } from '../../services/membership.service';

@Component({
  selector: 'app-kontakter',
  templateUrl: './kontakter.component.html',
  styleUrls: ['./kontakter.component.css']
})
export class KontakterComponent implements OnInit {


  displayDialog: boolean;

  Contact: Contact = {};

  selectedContact: Contact;

  newContact: boolean;

  Contacts: Contact[];

  cols: any[];

  test: string = "Her Tester vi stringintrop"

  showSpinner: boolean = true;

  isEmailValid: boolean = true;
  isSaveClicked: boolean = false;

  constructor(private contactService: ContactService,private membershipService: MembershipService, private messageService: MessageService, private auth: AuthService, private confirmationService: ConfirmationService) { }

  ngOnInit() {

    //create table headers
    this.cols =
    [
      { field: "FirstName", header: "Fornavn" },
      { field: "LastName", header: "Etternavn" },
      { field: "Email", header: "E-postadresse" },
      { field: "Mobile", header: "Telefonnummer" },
      { field: "Title", header: "Stilling" },
      //{ field: "Department", header: "Avdeling" },
      { field: "HasRelatedAgreement", header: "Avtalekontakt" },
      //{ field: "", header: "" }
      ];

      
    //use service to get contacts
    this.getContacts();


    

    //this.Contacts = [
    //  { ID: 1, Name: "Alex", Email: "AMPE@columbusglobal.com", Phone: "94094479", Title: "System Developer", Department: "CRM" },
    //  { ID: 2, Name: "Test", Email: "testing@gmail.com", Phone: "12341234", Title: "System Test", Department: "CRM" },
    //  { ID: 3, Name: "Gjøran", Email: "Gjøran@test.com", Phone: "98653212", Title: "Developer", Department: "CRM" }
    //]




  }

  getContacts() {
    this.showSpinner = true;
    if(this.auth.Contacts!=null&&this.auth.Contacts!=undefined)
    {
      this.Contacts = this.auth.Contacts;
      this.showSpinner = false;
    }
    else if(this.auth.account.Id!=null)
    {
      this.contactService.getAllContacts(this.auth.account.Id).subscribe(x => {
      this.auth.Contacts = x; 
      this.Contacts = x;
      //this.Contacts.forEach(function (val) {
      //  if (val.HasRelatedAgreement) {
      //    val.inAgreement = "Ja";
      //  } else {
      //    val.inAgreement = "Nei";
      //  }


      //});

     // console.table(x);
      this.showSpinner = false;
    });
    }
    else
    {
      
    this.auth.getAccountInfo().subscribe(x => {      
   // console.log("GetAccountInfo: response from api");
   // console.log(x);
    if (!x) {
    //  console.log(x + "no CRMAccount found");
      //this.logout();
    } else {
    //  console.log("GetAccountInfo: Account was found");
    this.auth.account=x;
      this.contactService.getAllContacts(x.Id).subscribe(x => {
        this.auth.Contacts = x;
        this.Contacts = x;
        //this.Contacts.forEach(function (val) {
        //  if (val.HasRelatedAgreement) {
        //    val.inAgreement = "Ja";
        //  } else {
        //    val.inAgreement = "Nei";
        //  }
        

        //});

       // console.table(x);
       this.membershipService.getMembershipsById(this.auth.account.Id).subscribe(x => {
        x.forEach(function (memberships) {
          memberships.Status = memberships.Status == "0" ? "Aktiv" : "Inaktiv";          
          let date = new Date(memberships.StartDate.toString());
          memberships.ShortStartDateString = date.toLocaleDateString();
  
          if (memberships.ShortStopDateString != null) {
            let date = new Date(memberships.StopDate.toString());
            memberships.ShortStopDateString = date.toLocaleDateString();           
          }
          if (memberships.ExpiryDate != null) {
            let date = new Date(memberships.ExpiryDate.toString());
            memberships.ShortExpiryDateString = date.toLocaleDateString();
          }
        });
        this.auth.Avtaler = x;
        
       // console.table(this.Avtaler);
       
      });
       this.showSpinner = false;
      });
    }
    });
    }
  }


  showDialogToAdd() {
    this.newContact = true;
    this.Contact = {};
    this.displayDialog = true;
  }

  save() {
    this.isSaveClicked = true;
    
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (this.Contact.Email.match(regexp)) {
      this.isEmailValid = true;
    if(this.auth.account!=undefined&&this.auth.account.Id!=undefined&&this.auth.account.Id!=null)
    {
      let Contacts = [...this.Contacts];
        if (this.newContact) {
          let con = this.Contact;
          this.Contact.MinsideContact=true;
          this.contactService.createNewContact(this.Contact, this.auth.account.Id).subscribe(res => {
            if (res) {
              Contacts.push(con);
              
              this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Lagring fullført!' });
              location.reload();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Lagring feilet' });
            }
          });
        }
        else {
          let con = this.Contact;
          this.contactService.saveContact(this.Contact).subscribe(res => {
            
            if (res) {
              con.Name = con.FirstName + " " + con.LastName;
              Contacts[this.Contacts.indexOf(this.selectedContact)] = con;
             
              this.isSaveClicked = false;
              this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Lagring fullført!' });
              location.reload();
              
            } else {
              
              this.isSaveClicked = false;
              this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Lagring feilet' });
              
            }
          });
        }

        this.Contacts = Contacts;
        this.Contact = {};
        this.displayDialog = false;
    }
    else
    {
      this.auth.getAccountInfo().subscribe(x => {
        let Contacts = [...this.Contacts];
        if (this.newContact) {
          let con = this.Contact;
          
          this.contactService.createNewContact(this.Contact, x.Id).subscribe(res => {
            if (res) {
              Contacts.push(con);
              this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Lagring fullført!' });
              location.reload();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Lagring feilet' });
            }
          });
        }
        else {
          let con = this.Contact;
          this.contactService.saveContact(this.Contact).subscribe(res => {
            
            if (res) {
              con.Name = con.FirstName + " " + con.LastName;
              Contacts[this.Contacts.indexOf(this.selectedContact)] = con;
             
              this.isSaveClicked = false;
              this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Lagring fullført!' });
              location.reload();
              
            } else {
              
              this.isSaveClicked = false;
              this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Lagring feilet' });
              
            }
          });
        }

        this.Contacts = Contacts;
        this.Contact = {};
        this.displayDialog = false;
      });
    }
    } else {
      this.isEmailValid = false;
      
    }
  }

  delete() {
   // console.log(this.selectedContact);
    this.contactService.disableContact(this.selectedContact).subscribe(x => {
      if (x)
      {
        let index = this.Contacts.indexOf(this.selectedContact);
        this.Contacts.splice(index, 1);
        this.Contact = {};
        this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Deleted contact!' });
      } else
      {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Failed to delete contact' });
      }

      this.displayDialog = false;

    },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Failed to delete contact: Internal Server Error' });
      });

  }
  removeModal() {
    this.displayDialog = false;
    this.isSaveClicked = false;
    this.isEmailValid = true;
  }

  onRowSelect(event) {
    this.newContact = false;
    this.Contact = this.cloneContact(event.data);
    this.displayDialog = true;
  }

  cloneContact(c: Contact): Contact {
    let Contact = {};
    for (let prop in c) {
      Contact[prop] = c[prop];
    }
    return Contact;
  }

  
  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Vil du slette kontakten?',
      header: 'Bekreft Sletting av kontakt',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Ja',
      rejectLabel: 'Nei',
      accept: () => {
        this.delete();
      },
      reject: () => {
       
      }
    });
  }

}


interface SelectItem {
  label?: string;
  value: any;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}
