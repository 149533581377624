import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { CRMAccount, InvoiceType } from '../../models/CRMAccount';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SelectItem } from 'primeng/api';
import { CrmAccountService } from '../../services/crm-account.service';
import { error } from 'util';
import { forEach } from '@angular/router/src/utils/collection';
import { AuthService } from '../../services/auth.service';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product';
import { Reasons } from '../abonnement/abonnement.component';
import { MembershipService, Reason } from '../../services/membership.service';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from '../../services/case.service';
import { ContactService } from '../../services/contact.service';
import { access } from 'fs';
@Component({
  selector: 'app-registrer',
  templateUrl: './registrer.component.html',
  styleUrls: ['./registrer.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false, showError: true }
  }]
})
export class RegistrerComponent implements OnInit {
  SignupReason: Reasons[];
  orgNumber: boolean;
  KrivstillerEnable: boolean = false;// devops 3232
  KravstillerValidation: boolean = false;// devops 3232
  KravstillerFocusOut: boolean = false;// devops 3232
  TransportmerkingReasonCodes: any;// devops 3232 (when transportmerking is selected)
  Gtin13_14_8_FDA: boolean;// devops 3351
  Gtin13_14_8_FDADisplay: boolean;// devops 3351
  productnumber: number;// devops 3351
  emailregex: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  MinGtinVisible: boolean;
  regCustomerInfo: FormGroup;
  regInvoiceInfo: FormGroup;
  regProduct: FormGroup;
  Confirmation: FormGroup;
  OrgName: string;
  account: CRMAccount = {
    BillingAddress: { AddressLine1: "", City: "", Country: "", Postalcode: "" },
    MailAddress: { AddressLine1: "", City: "", Country: "", Postalcode: "" },
    VisitingAddress: { AddressLine1: "", City: "", Country: "", Postalcode: "" },
    Turnover: { Amount: "" }
  };


  constructor(
    private fb: FormBuilder,
    private accountService: CrmAccountService,
    private auth: AuthService,
    private productService: ProductService,
    private membershipService: MembershipService,
    private route: ActivatedRoute,
    private CaseService: CaseService,
    private contact: ContactService,
  ) { }

  ngOnInit() {
    this.SubmitDone = false;
    if (this.auth.isLoggedIn()) {
      this.auth.getAccountInfo().subscribe(x => {

        this.getProducts(x.Turnover.Amount);

      });


    } else {



      this.regCustomerInfo = this.fb.group({
        AccountName: ["", [Validators.required]],
        OrgNumber: ["", [Validators.required]],
        Email: ["", [Validators.pattern(this.emailregex), Validators.required]],
        Telephone: ["", [Validators.required]],
        ContactFirstName: ["", [Validators.required]],
        ContactLastName: ["", [Validators.required]],
        ContactPhone: ["", [Validators.required]],
        ContactEmail: ["", [Validators.pattern(this.emailregex), Validators.required]],
        RequesterName: ["", [Validators.required]],
        RequesterEmail: ["", [Validators.pattern(this.emailregex), Validators.required]],
        RequesterPhone: ["", [Validators.required]],
        VisitingAddressCheck: [false, []],
        VisitingAddressCheckonFalse: [false, []],
        //VisitingCO: ["", [Validators.required]],
        VisitingAddress: ["", [Validators.required]],
        VisitingAddress2: ["", []],
        VisitingPostalCode: ["", [Validators.required]],
        VisitingCountry: ["", [Validators.required]],
        VisitingCity: ["", [Validators.required]],
        //VisitingRegion: ["", [Validators.required]],

        //MailCO: ["", [Validators.required]],
        MailAddress: ["", [Validators.required]],
        MailAddress2: ["", []],
        MailPostalCode: ["", [Validators.required]],
        MailCountry: ["", [Validators.required]],
        MailCity: ["", [Validators.required]],
        //MailRegion: ["", [Validators.required]],

        Department: ["", [Validators.required]],
        TurnoverCategory: ["", []],

        //Websiteurl: [this.account.Websiteurl, []],
      });


      //Faktura
      this.regInvoiceInfo = this.fb.group({
        //InvoiceCO: ["", [Validators.required]],
        InvoiceAddress: ["", [Validators.required]],
        InvoiceAddress2: ["", []],
        InvoicePostalCode: ["", [Validators.required]],
        InvoiceCountry: ["", [Validators.required]],
        InvoiceCity: ["", [Validators.required]],
        //InvoiceRegion: ["", [Validators.required]],

        InvoiceAddressCheck: [false, []],
        POnumber: ["", []],
      });



    }

    //Produkt
    this.regProduct = this.fb.group({
      selectedProduct: ["", [Validators.required]],
      IsGSRN: ["", []],
      Målepunkt: ["", []],
      reason: ["", []],
      gtinudi: ["", []],
      reasonText: ["", []],
      numberOfmeasuringpoints: ["", [Validators.required]],
      Comments: ["", []],
      Kravstiller: [this.KrivstillerEnable ? ["", [Validators.required]] : []], //devops 3232
      // Email: [this.account.Email != null ? this.account.Email : "", [Validators.pattern(this.emailregex), Validators.required]],
      // AccountName: (this.account.ProffForvaltUnauthorize) ? ["", [Validators.required]] : [{ value: this.account.Name, disabled: true }, [Validators.required]],
    });

    this.accountService.getIndustryCodes().subscribe(x => {

      x.forEach(x => {
        if (x.Label.toUpperCase() != "ANNET") {
          this.Departments.push({ label: x.Label, value: x.Value });
        }

      });
      var annet = x.find(x => x.Label.toUpperCase() == "ANNET");
      if (annet != undefined) {
        this.Departments.push({ label: annet.Label, value: annet.Value });
      }
      //this.Departments = x;
      // console.log(this.Departments);
    }, error => {
      //  console.log(error);
    });

  }

  turnover0: boolean = false;

  RequiredFieldsChange(event) {
    if (event) {
      this.regCustomerInfo = this.fb.group({
        AccountName: ["", [Validators.required]],
        OrgNumber: ["", [Validators.required]],
        Email: ["", [Validators.pattern(this.emailregex), Validators.required]],
        Telephone: ["", [Validators.required]],
        ContactFirstName: ["", [Validators.required]],
        ContactLastName: ["", [Validators.required]],
        ContactPhone: ["", [Validators.required]],
        ContactEmail: ["", [Validators.required, Validators.pattern(this.emailregex)]],
        RequesterName: ["", [Validators.required]],
        RequesterEmail: ["", [Validators.pattern(this.emailregex), Validators.required]],
        RequesterPhone: ["", [Validators.required]],
        VisitingAddressCheck: [false, []],
        VisitingAddressCheckonFalse: [false, []],
        //VisitingCO: ["", [Validators.required]],
        VisitingAddress: ["", [Validators.required]],
        VisitingAddress2: ["", []],
        VisitingPostalCode: ["", [Validators.required]],
        VisitingCountry: ["", [Validators.required]],
        VisitingCity: ["", [Validators.required]],
        //VisitingRegion: ["", [Validators.required]],

        //MailCO: ["", [Validators.required]],
        MailAddress: ["", [Validators.required]],
        MailAddress2: ["", []],
        MailPostalCode: ["", [Validators.required]],
        MailCountry: ["", [Validators.required]],
        MailCity: ["", [Validators.required]],
        //MailRegion: ["", [Validators.required]],

        Department: ["", [Validators.required]],
        TurnoverCategory: ["", []],



        //Websiteurl: [this.account.Websiteurl, []],
      });

      this.regInvoiceInfo = this.fb.group({
        //InvoiceCO: ["", [Validators.required]],
        InvoiceAddress: ["", [Validators.required]],
        InvoiceAddress2: ["", []],
        InvoicePostalCode: ["", [Validators.required]],
        InvoiceCountry: ["", [Validators.required]],
        InvoiceCity: ["", [Validators.required]],
        //InvoiceRegion: ["", [Validators.required]],

        InvoiceAddressCheck: [false, []],
        POnumber: ["", []],
      });

    } else {
      this.regCustomerInfo = this.fb.group({
        AccountName: ["", [Validators.required]],
        OrgNumber: ["", []],
        Email: ["", [Validators.pattern(this.emailregex), Validators.required]],
        Telephone: ["", [Validators.required]],
        ContactFirstName: ["", [Validators.required]],
        ContactLastName: ["", [Validators.required]],
        ContactPhone: ["", [Validators.required]],
        ContactEmail: ["", [Validators.required, Validators.pattern(this.emailregex)]],
        RequesterName: ["", [Validators.required]],
        RequesterEmail: ["", [Validators.pattern(this.emailregex), Validators.required]],
        RequesterPhone: ["", [Validators.required]],
        VisitingAddressCheck: [false, []],
        VisitingAddressCheckonFalse: [false, []],
        //VisitingCO: ["", []],
        VisitingAddress: ["", [Validators.required]],
        VisitingAddress2: ["", []],
        VisitingPostalCode: ["", [Validators.required]],
        VisitingCountry: ["", []],
        VisitingCity: ["", [Validators.required]],
        // VisitingRegion: ["", []],

        //MailCO: ["", []],
        MailAddress: ["", [Validators.required]],
        MailAddress2: ["", []],
        MailPostalCode: ["", [Validators.required]],
        MailCountry: ["", []],
        MailCity: ["", [Validators.required]],
        // MailRegion: ["", []],

        Department: ["", [Validators.required]],
        TurnoverCategory: ["", []],

      });

      this.regInvoiceInfo = this.fb.group({
        //InvoiceCO: ["", []],
        InvoiceAddress: ["", [Validators.required]],
        InvoiceAddress2: ["", []],
        InvoicePostalCode: ["", [Validators.required]],
        InvoiceCountry: ["", []],
        InvoiceCity: ["", [Validators.required]],
        //InvoiceRegion: ["", []],

        InvoiceAddressCheck: [false, []],
        POnumber: ["", []],
      });

    }
  }
  getUser() {
    return this.auth.isLoggedIn();
  }

  NotNorwegian: boolean = false;

  //produktBools{
  IsGSRN: boolean;
  IsGTIN8: boolean;
  HasInactiveAgreements: boolean
  IsProduktmarking: boolean;
  IsTransport: boolean;

  IsFood: boolean;


  IsStkPris: boolean;
  IsKgPris: boolean;
  GtinUDI: boolean = null;
  IsAbove100WeightProduct: boolean;
  IsAbove300EachProduct: boolean;

  types: SelectItem[];

  orgSpinner: boolean = false;
  message: string = "";

  SetIsGSRN(event) {
    //  console.log(event);
    this.IsGSRN = event;
    //  console.log(this.IsGSRN);
    if (event) {
      this.IsGTIN8 = null;
      this.IsProduktmarking = null;
      this.IsTransport = null;
      this.IsFood = null;
      this.IsStkPris = null;
      this.IsKgPris = null;
      this.IsAbove100WeightProduct = null;
      this.IsAbove300EachProduct = null;
    } else {
      //nullstill målepunkt
    }

  }
  SetIsGTIN8(event) {
    this.IsGTIN8 = event;

    if (event) {
      this.IsProduktmarking = null;
      this.IsTransport = null;
      this.IsFood = null;
      this.IsStkPris = null;
      this.IsKgPris = null;
      this.IsAbove100WeightProduct = null;
      this.IsAbove300EachProduct = null;
    }
  }
  SetIsProduktmarking(event) {
    // console.log(event);
    if (event.value == 1) {
      this.IsTransport = true;
      this.IsProduktmarking = false;

      this.IsFood = null;
      this.IsStkPris = null;
      this.IsKgPris = null;
      this.IsAbove100WeightProduct = null;
      this.IsAbove300EachProduct = null;

    } else if (event.value == 2) {
      this.IsTransport = false;
      this.IsProduktmarking = true;
    } else {
      this.IsTransport = true;
      this.IsProduktmarking = true;
    }
    //  console.log(this.IsProduktmarking);
  }
  SetIsGTIN13() {
    this.IsGSRN = null
    this.IsGTIN8 = null;
    this.IsProduktmarking = true;
    this.IsTransport = null;
    this.IsFood = true;
    this.IsStkPris = null;
    this.IsKgPris = null;
    this.IsAbove100WeightProduct = null;
    this.IsAbove300EachProduct = null;
  }
  SetIsTransport() {
    this.IsGSRN = null
    this.IsGTIN8 = null;
    this.IsProduktmarking = null;
    this.IsTransport = true;
    this.IsFood = null;
    this.IsStkPris = null;
    this.IsKgPris = null;
    this.IsAbove100WeightProduct = null;
    this.IsAbove300EachProduct = null;
  }


  SetIsFood(event) {
    this.IsFood = event.value;
    if (!event.value) {
      this.IsStkPris = true;
      this.IsKgPris = false;
    } else {
      this.IsStkPris = null;
      this.IsKgPris = null;
    }

  }

  SetIsStkPris(event) {

    if (event.value == 1) {
      this.IsStkPris = true;
      this.IsKgPris = false;
      this.IsAbove100WeightProduct = null;
      this.regProduct.setControl('gtinudi', new FormControl(null, [Validators.required]));
    } else if (event.value == 2) {
      this.IsStkPris = false;
      this.IsKgPris = true;
      this.IsAbove300EachProduct = null;
      this.regProduct.setControl('gtinudi', new FormControl(null, []));
      this.Gtin13_14_8_FDADisplay = false;
    } else {
      this.IsKgPris = true;
      this.IsStkPris = true;
      this.regProduct.setControl('gtinudi', new FormControl(null, [Validators.required]));
    }

  }
  SetIsUDI(event) {
    this.regProduct.get('gtinudi').setValue('');
    if (event.value == 1) {
      this.regProduct.get('gtinudi').setValue(true);
      this.Gtin13_14_8_FDADisplay = true;
    }
    else if (event.value == 0) {
      this.regProduct.get('gtinudi').setValue(false);
      this.Gtin13_14_8_FDADisplay = false;
    }

  }
  SetIsFDA(event) {

    if (event.value == 1) {

      this.Gtin13_14_8_FDA = true;
    }
    else if (event.value == 0) {

      this.Gtin13_14_8_FDA = false;
    }

  }

  SetIsAbove100WeightProduct(event) {
    this.IsAbove100WeightProduct = event.value;

    //get product and price from crm
  }
  SetIsAbove300EachProduct(event) {
    this.IsAbove300EachProduct = event.value;

    //get product and price from crm


  }

  //}
  orgnr: string;
  Comment: string;

  Departments: any[] = [];
  VisitingAddress: boolean;
  Godkjenning: boolean;
  InvoiceAddress: boolean;
  startFee: string;
  subscription: string;
  FakturaTyper: any[] = [

    { name: 'EHF', value: 100, checked: true },
    { name: 'E-post', value: 101, checked: false },
    { name: 'Papir (Tillegg på 50,- kroner)', value: 102, checked: false }

  ]
  SelectedItem: number = 100;
  FakturaType: string;
  billingtypechange(event) {

    this.SelectedItem = event.value;
    // console.log(this.SelectedItem);
    if (event.value == 101) {
      this.regInvoiceInfo.addControl("InvoiceEmail", new FormControl(null, [Validators.pattern(this.emailregex), Validators.required]))
    } else {
      if (this.regInvoiceInfo.get("InvoiceEmail") != null) {
        this.regInvoiceInfo.removeControl("InvoiceEmail");
      }
    }
  }

  GetOrg() {
    //console.log(this.orgnr);

    this.orgnr = this.orgnr.split(" ").join("");
    if (this.orgnr.length >= 9) { /*length of org number is always 9  */
      this.orgSpinner = true;
      this.accountService.getAccountWithOrgNr(this.orgnr).subscribe(x => {
        console.log(x);
        this.account = x;
        if (this.account.Turnover.Amount == null) {
          this.turnover0 = true;
        }
        if (this.account.BillingAddress == null) {
          this.account.BillingAddress = { AddressLine1: "", City: "", Country: "", Postalcode: "" }
        }
        if (this.account.MailAddress == null) {
          this.account.MailAddress = { AddressLine1: "", City: "", Country: "", Postalcode: "" }
        } if (this.account.VisitingAddress == null) {
          this.account.VisitingAddress = { AddressLine1: "", City: "", Country: "", Postalcode: "" }
        }
        if (this.account.OrganizationNumber == null) {
          this.account.OrganizationNumber = x.IndentificationNumber;
          //  console.log(this.account);
        }
        this.account.BillingAddress.Country = "Norge";
        this.account.VisitingAddress.Country = "Norge";
        this.account.MailAddress.Country = "Norge";

        this.orgSpinner = false;

        if (x.Id == "00000000-0000-0000-0000-000000000000" || (x.Id != "00000000-0000-0000-0000-000000000000" && this.auth.isLoggedIn)) {// not in crm       

          this.orgNumber = true;




          ///////////////////
          //bedriften
          this.regCustomerInfo = this.fb.group({
            ManualRegister: (this.account.isProffForvaltNotResponding),
            AccountName: ((this.account.ProffForvaltUnauthorize) && (!this.account.isProffForvaltNotResponding)) ? ["", [Validators.required]] : [{ value: this.account.Name, disabled: !this.account.isProffForvaltNotResponding }, [Validators.required]],
            
            OrgNumber: ((this.account.ProffForvaltUnauthorize) && (!this.account.isProffForvaltNotResponding)) ? ["", [Validators.required, Validators.minLength(9), Validators.maxLength(9)]] : [{ value: this.account.OrganizationNumber, disabled: !this.account.isProffForvaltNotResponding }, [Validators.required]],

            Email: this.account.Email,
            //Email: [this.account.Email != null ? this.account.Email : "", [Validators.pattern(this.emailregex), Validators.required]],
            Telephone: [this.account.Telephone, [Validators.required]],
            ContactFirstName: ["", [Validators.required]],
            ContactLastName: ["", [Validators.required]],
            ContactPhone: ["", [Validators.required]],
            ContactEmail: ["", [Validators.required, Validators.pattern(this.emailregex)]],
            RequesterName: ["", [Validators.required]],
            RequesterEmail: ["", [Validators.pattern(this.emailregex), Validators.required]],
            RequesterPhone: ["", [Validators.required]],
            VisitingAddressCheck: [false, []],
            VisitingAddressCheckonFalse: [false, []],
            VisitingAddress: [{value: this.account.VisitingAddress.AddressLine1, disabled: !this.account.isProffForvaltNotResponding}, [Validators.required]],
            VisitingAddress2: [{value: this.account.VisitingAddress.AddressLine2, disabled: !this.account.isProffForvaltNotResponding}, []],
            VisitingPostalCode: [{value: this.account.VisitingAddress.Postalcode, disabled: !this.account.isProffForvaltNotResponding}, [Validators.required]],
            VisitingCountry: [{value: this.account.VisitingAddress.Country, disabled: !this.account.isProffForvaltNotResponding}, [Validators.required]],
            VisitingCity: [{value: this.account.VisitingAddress.City, disabled: !this.account.isProffForvaltNotResponding}, [Validators.required]],

            MailAddress: [{value: this.account.MailAddress.AddressLine1, disabled: !this.account.isProffForvaltNotResponding}, [Validators.required]],
            MailAddress2: [{ value: this.account.MailAddress.AddressLine2, disabled: !this.account.isProffForvaltNotResponding }, []],
            MailPostalCode: [{value: this.account.MailAddress.Postalcode, disabled: !this.account.isProffForvaltNotResponding}, [Validators.required]],
            MailCountry: [{value: this.account.MailAddress.Country, disabled: !this.account.isProffForvaltNotResponding}, [Validators.required]],
            MailCity: [{value: this.account.MailAddress.City, disabled: !this.account.isProffForvaltNotResponding}, [Validators.required]],

            Department: ["", [Validators.required]],
            TurnoverCategory: [{ value: this.account.Turnover.Amount, disabled: this.account.Turnover.Amount != null }, this.account.Turnover.Amount == null ? [Validators.required] : []],

            //Websiteurl: [this.account.Websiteurl, []],
          });
          ///////////////////////
          this.regInvoiceInfo = this.fb.group({
            InvoiceAddress: [this.account.BillingAddress.AddressLine1, [Validators.required]],
            InvoiceAddress2: [this.account.BillingAddress.AddressLine2, []],
            InvoicePostalCode: [this.account.BillingAddress.Postalcode, [Validators.required]],
            InvoiceCountry: [this.account.BillingAddress.Country, [Validators.required]],
            InvoiceCity: [this.account.BillingAddress.City, [Validators.required]],
            InvoiceAddressCheck: [false, []],
            POnumber: ["", []],
            //InvoiceRadiobuttons: [InvoiceType.EHF, [Validators.required]]
          });
          this.message = "";
        } else {//in crm
          this.message = "Bedriften er allerede medlem i våre systemer";

        }
      },
        error => {
          // console.log(error);

          this.orgSpinner = false;
          this.orgNumber = false;
          this.message = "Vi finner dessverre ingen bedrift i enhetsregisteret med dette org.nummeret.";
        });

    }


  }
  orgControll() {

    if (this.NotNorwegian) {
      return true;
    }
    if (!this.account.fromProff) {
      return false;
    }
    else if (this.account.ProffForvaltUnauthorize) {
      return false;
    }
    else if (this.account.IsInCRM && this.account["IsOrgNoDisabled"]) {
      return false;
    }
    else if (this.account.IsInCRM && (this.account.IsMember || this.account.HasInactiveAgreements) && !this.account["IsOrgNoDisabled"]) {
      return false;
    }
    else {
      return true;
    }


  }

  onSubmit() {
debugger
    let resoncodeTosave: any; //devops 3232  
    let KravstillerTemp: string;
    // console.log(this.account);

    let _transportmerkingReasonCodes = this.TransportmerkingReasonCodes;
    if (this.auth.isLoggedIn()) {
      this.auth.getAccountInfo().subscribe(x => {

debugger
        let account: CRMAccount = x;
        //let product: Product = this.selectedProduct;
        //let reasonText: string = this.regProduct.get('reasonText').value;
        //  console.log(account);


        let regProduct = this.regProduct.value;

        //devops 3232        
        if (_transportmerkingReasonCodes != null && _transportmerkingReasonCodes[0] != null) {
          resoncodeTosave = { Id: _transportmerkingReasonCodes[0].Id != null ? _transportmerkingReasonCodes[0].Id : "" };
        }
        else {
          resoncodeTosave = { Id: regProduct.reason != null && regProduct.reason.Id != null ? regProduct.reason.Id : "" };//regular
        }

        if (regProduct.Kravstiller != null && regProduct.Kravstiller.length == 0) {
          KravstillerTemp = '';
        }
        else {
          KravstillerTemp = regProduct.Kravstiller;
        }

        this.NewCase = {
          Kravstiller: KravstillerTemp, //Devops 3232
          AccountOrganizationalnumber: account.OrganizationNumber == null ? account.IndentificationNumber : account.OrganizationNumber,
          AccountVisitingAddress: {
            AddressLine1: account.VisitingAddress.AddressLine1,
            AddressLine2: account.VisitingAddress.AddressLine2,
            City: account.VisitingAddress.City,
            Postalcode: account.VisitingAddress.Postalcode,
          },
          Country_AccountVisitingAddress: account.VisitingAddress.Country,
          AccountPostalAddress: {
            AddressLine1: account.MailAddress.AddressLine1,
            AddressLine2: account.MailAddress.AddressLine2,
            City: account.MailAddress.City,
            Postalcode: account.MailAddress.Postalcode,
          },
          Country_AccountPostalAddress: account.MailAddress.Country,
          AccountInvoiceAddress: {
            AddressLine1: account.BillingAddress.AddressLine1,
            AddressLine2: account.BillingAddress.AddressLine2,
            City: account.BillingAddress.City,
            Postalcode: account.BillingAddress.Postalcode,
          },
          Country_AccountInvoiceAddress: account.BillingAddress.Country,
          AccountInvoiceEmail: account.InvoiceEmail,
          AccountTurnoverNOK: account.Turnover.Amount,
          AccountInvoiceType: account.InvoiceType,
          AgreementContactFirstName: null,
          AgreementContactLastName: null,
          AccountEmail: account.Email,
          AccountPhoneNumber: account.Telephone,
          AgreementContactEmail: null,
          AgreementContactPhoneNumber: null,
          PurchaseOrder: null,
          IndustryCode: null,
          ProductId: { id: regProduct.selectedProduct.Id },
          Comments: regProduct.Comments != null ? regProduct.Comments : "",

          Account: account,
          RequesterName: localStorage.getItem("Username"),
          RequesterEmail: this.auth.getUserEmail(),
          RequesterPhone: account.Telephone,

          //ReasonCodeId: { Id: regProduct.reason != null && regProduct.reason.Id != null ? regProduct.reason.Id : "" },
          ReasonCodeId: resoncodeTosave,////Devops 3232
          ReasonText: regProduct.reasonText,
          GtinUDI: regProduct.gtinudi,
          MinGtinVisible: this.MinGtinVisible,
          NumberOfmeasuringpoints: regProduct.numberOfmeasuringpoints != null ? regProduct.numberOfmeasuringpoints : "",

          AccountTurnoverYear: account.Turnover.Year,
          NACE_Code: account.NACE_Code,
          Gtin13Or14Or8FDA: this.Gtin13_14_8_FDA

        }

        this.contact.getContactByMail(this.auth.getUserEmail()).subscribe(x => {
          debugger
          this.NewCase.RequesterName = x.Name;
          this.SubmitStarted = true;
          this.CaseService.createCase(this.NewCase).subscribe(x => {
            debugger
            this.SubmitDone = true;
            this.SubmitStarted = false;
            //  console.log("Submit Done!");
          }, error => {
            //   console.log("Error");
            //  console.log(error);
          });

        });

      });

    } else {

      // console.log("new user and product");
      let invoiceSameAsPostCheck = this.regCustomerInfo.get('VisitingAddressCheck').value;
      let regCustomer = this.regCustomerInfo.value;
      let regInvoiceCustomer = this.regInvoiceInfo.value;
      let regProduct = this.regProduct.value;


      //devops 3232        
      if (_transportmerkingReasonCodes != null && _transportmerkingReasonCodes[0] != null) {
        resoncodeTosave = { Id: _transportmerkingReasonCodes[0].Id != null ? _transportmerkingReasonCodes[0].Id : "" };
      }
      else {
        resoncodeTosave = { Id: regProduct.reason != null && regProduct.reason.Id != null ? regProduct.reason.Id : "" };//regular
      }

      if (regProduct.Kravstiller != null && regProduct.Kravstiller.length == 0) {
        KravstillerTemp = '';
      }
      else {
        KravstillerTemp = regProduct.Kravstiller;
      }
        console.log(regCustomer);
        console.log(regInvoiceCustomer);
        console.log(regProduct);
        this.regInvoiceInfo.value.InvoiceAddress2;
      //setting the addresses fields

      if(!this.account.isProffForvaltNotResponding){
        regCustomer.MailAddress = this.account.MailAddress.AddressLine1;
        regCustomer.MailAddress2 = this.account.MailAddress.AddressLine2;
        regCustomer.MailCity= this.account.MailAddress.City ;
        regCustomer.MailCountry = this.account.MailAddress.Country;
        regCustomer.MailPostalCode =this.account.MailAddress.Postalcode;
  
        regCustomer.VisitingAddress = this.account.VisitingAddress.AddressLine1;
        regCustomer.VisitingAddress2 =this.account.VisitingAddress.AddressLine2;
        regCustomer.VisitingCity = this.account.VisitingAddress.City;
        regCustomer.VisitingCountry = this.account.VisitingAddress.Country;
        regCustomer.VisitingPostalCode=  this.account.VisitingAddress.Postalcode;
      }
      else{

      }
      
      if(invoiceSameAsPostCheck){
        regInvoiceCustomer.InvoiceAddress = regCustomer.MailAddress;
        regInvoiceCustomer.InvoiceAddress2 = regCustomer.MailAddress2;
        regInvoiceCustomer.InvoiceCity = regCustomer.MailCity;
        regInvoiceCustomer.InvoiceCountry = regCustomer.MailCountry;
        regInvoiceCustomer.InvoicePostalCode = regCustomer.MailPostalCode;
      }
      else{
        //this.regInvoiceInfo.value.InvoiceAddress2;
        regInvoiceCustomer.InvoiceAddress = this.regInvoiceInfo.value.InvoiceAddress;
        regInvoiceCustomer.InvoiceAddress2 =this.regInvoiceInfo.value.InvoiceAddress2;
        regInvoiceCustomer.InvoiceCity = this.regInvoiceInfo.value.InvoiceCity;
        regInvoiceCustomer.InvoiceCountry = this.regInvoiceInfo.value.InvoiceCountry;
        regInvoiceCustomer.InvoicePostalCode = this.regInvoiceInfo.value.InvoicePostalCode;
      }
      //settings end

      /*if (regCustomer.VisitingAddressCheck) {
        regCustomer.VisitingAddress = regCustomer.MailAddress;
        regCustomer.VisitingAddress2 = regCustomer.MailAddress2;
        regCustomer.VisitingCity = regCustomer.MailCity;
        regCustomer.VisitingCountry = regCustomer.MailCountry;
        regCustomer.VisitingPostalCode = regCustomer.MailPostalCode;
      }
      if (regInvoiceCustomer.InvoiceAddressCheck) {
        regInvoiceCustomer.InvoiceAddress = regCustomer.MailAddress;
        regInvoiceCustomer.InvoiceAddress2 = regCustomer.MailAddress2;
        regInvoiceCustomer.InvoiceCity = regCustomer.MailCity;
        regInvoiceCustomer.InvoiceCountry = regCustomer.MailCountry;
        regInvoiceCustomer.InvoicePostalCode = regCustomer.MailPostalCode;
      }*/
      this.account.Lastname = this.regCustomerInfo.get('AccountName').value
      if(this.account.isProffForvaltNotResponding){
        this.account.Name = this.regCustomerInfo.get('AccountName').value
      }
      
      //this.account.Lastname = regCustomer.AccountName;


      this.NewCase = {
        Kravstiller: KravstillerTemp, //Devops 3232
        AccountOrganizationalnumber: regCustomer.OrgNumber == null ? this.account.OrganizationNumber : regCustomer.OrgNumber,
        AccountVisitingAddress: {
          AddressLine1: regCustomer.VisitingAddress,
          AddressLine2: regCustomer.VisitingAddress2,
          City: regCustomer.VisitingCity,
          Postalcode: regCustomer.VisitingPostalCode,
        },
        Country_AccountVisitingAddress: regCustomer.VisitingCountry,
        AccountPostalAddress: {
          AddressLine1: regCustomer.MailAddress,
          AddressLine2: regCustomer.MailAddress2,
          City: regCustomer.MailCity,
          Postalcode: regCustomer.MailPostalCode,
        },
        Country_AccountPostalAddress: regCustomer.MailCountry,
        AccountInvoiceAddress: {
          AddressLine1: regInvoiceCustomer.InvoiceAddress,
          AddressLine2: regInvoiceCustomer.InvoiceAddress2,
          City: regInvoiceCustomer.InvoiceCity,
          Postalcode: regInvoiceCustomer.InvoicePostalCode,
        },
        Country_AccountInvoiceAddress: regInvoiceCustomer.InvoiceCountry,
        AccountInvoiceEmail: regInvoiceCustomer.InvoiceEmail,
        AccountTurnoverNOK: regCustomer.TurnoverCategory == null ? this.account.Turnover.Amount : regCustomer.TurnoverCategory,
        AccountInvoiceType: this.SelectedItem,
        AgreementContactFirstName: regCustomer.ContactFirstName,
        AgreementContactLastName: regCustomer.ContactLastName,
        AccountEmail: regCustomer.Email,
        AccountPhoneNumber: regCustomer.Telephone,
        AgreementContactEmail: regCustomer.ContactEmail,
        AgreementContactPhoneNumber: regCustomer.ContactPhone,
        PurchaseOrder: regInvoiceCustomer.POnumber,
        IndustryCode: regCustomer.Department,
        ProductId: { id: regProduct.selectedProduct.Id },
        Comments: regProduct.Comments != null ? regProduct.Comments : "",


        Account: this.account,
        RequesterName: this.Contactperson == false ? regCustomer.RequesterName : regCustomer.ContactFirstName + ' ' + regCustomer.ContactLastName,
        RequesterEmail: this.Contactperson == false ? regCustomer.RequesterEmail : regCustomer.ContactEmail,
        RequesterPhone: this.Contactperson == false ? regCustomer.RequesterPhone : regCustomer.ContactPhone,

        //ReasonCodeId: { Id: regProduct.reason != null && regProduct.reason.Id != null ? regProduct.reason.Id : "" },
        ReasonCodeId: resoncodeTosave,//Devops 3232
        ReasonText: regProduct.reasonText,
        GtinUDI: regProduct.gtinudi,
        MinGtinVisible: this.MinGtinVisible,
        NumberOfmeasuringpoints: regProduct.numberOfmeasuringpoints != null ? regProduct.numberOfmeasuringpoints : "",
        IsForeignOrganization: this.NotNorwegian,
        IsManualRegister: regCustomer.ManualRegister,
        Gtin13Or14Or8FDA: this.Gtin13_14_8_FDA
      }

      if (this.account.NACE_Code != null) {
        this.NewCase.NACE_Code = this.account.NACE_Code;
      }
      if (this.account.Turnover != null && this.account.Turnover.Year != null) {
        this.NewCase.AccountTurnoverYear = this.account.Turnover.Year;
      }






      //NewCase.AccountTurnoverYear = "";

      //NewCase.AgreementContactPosition = "";
      //NewCase.AgreementContactDepartment = "";

      //NewCase.RequesterName = "";
      //NewCase.RequesterEmail = "";



      this.SubmitStarted = true;
      this.CaseService.createCase(this.NewCase).subscribe(x => {
        this.SubmitDone = true;
        this.SubmitStarted = false;
        // console.log("Submit Done!");
      }, error => {
        //   console.log("Error");
        //   console.log(error);
      });
    }


  }

  SubmitDone: boolean = false;
  SubmitStarted: boolean = false;
  NewCase: NewCase;
  selectedInvoiceEmail() {
    return this.SelectedItem == 101;
  }

  //selectedInvoiceEHF() {
  //  return this.SelectedItem == 100;
  //}
  setAcceptAgreement(event) {
    this.Godkjenning = event;

  }

  setFakturaAdressAsPostAdress(event){
    if(event){

    }

  }

  setVisitingAddressToSame(event) {
      console.log(event);
debugger
    this.VisitingAddress = event;
    if (!this.NotNorwegian) {
      if (event) {
        this.regCustomerInfo.value.VisitingAddress2 = this.regCustomerInfo.value.MailAddress2;
        this.regCustomerInfo.value.VisitingAddress = this.regCustomerInfo.value.MailAddress;
        this.regCustomerInfo.value.VisitingPostalCode = this.regCustomerInfo.value.MailPostalCode;
        this.regCustomerInfo.value.MailCity = this.regCustomerInfo.value.MailCity;
        this.regCustomerInfo.value.MailCountry = this.regCustomerInfo.value.MailCountry;
        //this.regCustomerInfo.value.MailCountry = this.regCustomerInfo.value.VisitingCountry;
        //this.account.MailAddress.AddressLine1 = this.account.VisitingAddress.AddressLine1;
        //this.account.MailAddress.City = this.account.VisitingAddress.City;
        //this.account.MailAddress.Country = this.account.VisitingAddress.Country;
        //this.account.MailAddress.Postalcode = this.account.VisitingAddress.Postalcode;
        this.regCustomerInfo.setControl("VisitingAddress2", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingAddress", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingPostalCode", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingCity", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingCountry", new FormControl(null, []));

      } else {
        this.regCustomerInfo.setControl("VisitingAddress2", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingAddress", new FormControl(null, [Validators.required]));
        this.regCustomerInfo.setControl("VisitingPostalCode", new FormControl(null, [Validators.required]));
        this.regCustomerInfo.setControl("VisitingCountry", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingCity", new FormControl(null, [Validators.required]));
      }
    } else {
      if (event) {

        this.regCustomerInfo.value.VisitingAddress = this.regCustomerInfo.value.MailAddress;
        this.regCustomerInfo.value.VisitingPostalCode = this.regCustomerInfo.value.MailPostalCode;
        this.regCustomerInfo.value.VisitingCity = this.regCustomerInfo.value.MailCity;
        this.regCustomerInfo.value.VisitingCountry = this.regCustomerInfo.value.MailCountry;
        //this.regCustomerInfo.value.MailCO = this.regCustomerInfo.value.VisitingCO;
        this.regCustomerInfo.value.VisitingAddress2 = this.regCustomerInfo.value.MailAddress2;
        //this.regCustomerInfo.value.MailRegion = this.regCustomerInfo.value.VisitingRegion;

        //this.account.MailAddress.AddressLine1 = this.account.VisitingAddress.AddressLine1;
        //this.account.MailAddress.City = this.account.VisitingAddress.City;
        //this.account.MailAddress.Country = this.account.VisitingAddress.Country;
        //this.account.MailAddress.Postalcode = this.account.VisitingAddress.Postalcode;

        this.regCustomerInfo.setControl("VisitingAddress", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingPostalCode", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingCountry", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingCity", new FormControl(null, []));

        //this.regCustomerInfo.setControl("MailCO", new FormControl(null, []));
        this.regCustomerInfo.setControl("VisitingAddress2", new FormControl(null, []));
        //this.regCustomerInfo.setControl("MailRegion", new FormControl(null, []));

      } else {
        this.regCustomerInfo.setControl("VisitingAddress", new FormControl(null, [Validators.required]));
        this.regCustomerInfo.setControl("VisitingPostalCode", new FormControl(null, [Validators.required]));
        this.regCustomerInfo.setControl("VisitingCountry", new FormControl(null, [Validators.required]));
        this.regCustomerInfo.setControl("VisitingCity", new FormControl(null, [Validators.required]));
        // this.regCustomerInfo.setControl("MailCO", new FormControl(null, [Validators.required]));
        this.regCustomerInfo.setControl("VisitingAddress2", new FormControl(null, []));
        // this.regCustomerInfo.setControl("MailRegion", new FormControl(null, [Validators.required]));
      }
    }
  }

  setInvoiceAddressToNull(event){
    if(event){
      debugger
      this.regCustomerInfo.get('VisitingAddressCheck').setValue(false);
      this.regInvoiceInfo.setControl("InvoiceAddress2", new FormControl(null, []));
      this.regInvoiceInfo.setControl("InvoiceAddress", new FormControl(null, [Validators.required]));
      this.regInvoiceInfo.setControl("InvoicePostalCode", new FormControl(null, [Validators.required]));
      this.regInvoiceInfo.setControl("InvoiceCountry", new FormControl(null, []));
      this.regInvoiceInfo.setControl("InvoiceCity", new FormControl(null, [Validators.required]));
    }
    
  }

  setInvoiceAddressToSame(event) {
    // console.log(event);
    this.InvoiceAddress = event;
    if (!this.NotNorwegian) {
      if (event) {
        debugger
        this.regCustomerInfo.get('VisitingAddressCheckonFalse').setValue(false);
        console.log(this.regCustomerInfo.value.VisitingAddressCheck)
        this.regInvoiceInfo.value.InvoiceAddress2 = this.regCustomerInfo.value.MailAddress2;
        this.regInvoiceInfo.value.InvoiceAddress = this.regCustomerInfo.value.MailAddress;
        this.regInvoiceInfo.value.InvoicePostalCode = this.regCustomerInfo.value.MailPostalCode;
        this.regInvoiceInfo.value.InvoiceCity = this.regCustomerInfo.value.MailCity;
        this.regInvoiceInfo.value.InvoiceCountry = this.regCustomerInfo.value.MailCountry;
        if(this.account.isProffForvaltNotResponding){
          this.regInvoiceInfo.setControl("InvoiceAddress2", new FormControl({value: this.regCustomerInfo.value.MailAddress2, disabled: true}, []));
          this.regInvoiceInfo.setControl("InvoiceAddress", new FormControl({value: this.regCustomerInfo.value.MailAddress, disabled: true}, []));
          this.regInvoiceInfo.setControl("InvoicePostalCode", new FormControl({value: this.regCustomerInfo.value.MailPostalCode, disabled: true}, []));
          this.regInvoiceInfo.setControl("InvoiceCountry", new FormControl({value: this.regCustomerInfo.value.MailCountry, disabled: true}, []));
          this.regInvoiceInfo.setControl("InvoiceCity", new FormControl({value: this.regCustomerInfo.value.MailCity, disabled: true}, []));
        }else{
          this.regInvoiceInfo.setControl("InvoiceAddress2", new FormControl({value: this.account.MailAddress.AddressLine2, disabled: true}, []));
          this.regInvoiceInfo.setControl("InvoiceAddress", new FormControl({value: this.account.MailAddress.AddressLine1, disabled: true}, []));
          this.regInvoiceInfo.setControl("InvoicePostalCode", new FormControl({value: this.account.MailAddress.Postalcode, disabled: true}, []));
          this.regInvoiceInfo.setControl("InvoiceCountry", new FormControl({value: this.account.MailAddress.Country, disabled: true}, []));
          this.regInvoiceInfo.setControl("InvoiceCity", new FormControl({value: this.account.MailAddress.City, disabled: true}, []));
        }
        
      } else {
        this.regInvoiceInfo.setControl("InvoiceAddress2", new FormControl(null, []));
        this.regInvoiceInfo.setControl("InvoiceAddress", new FormControl(null, [Validators.required]));
        this.regInvoiceInfo.setControl("InvoicePostalCode", new FormControl(null, [Validators.required]));
        this.regInvoiceInfo.setControl("InvoiceCountry", new FormControl(null, []));
        this.regInvoiceInfo.setControl("InvoiceCity", new FormControl(null, [Validators.required]));
      }
    } else {
      if (event) {
debugger
        this.regInvoiceInfo.value.InvoiceAddress = this.regCustomerInfo.value.MailAddress;
        this.regInvoiceInfo.value.InvoicePostalCode = this.regCustomerInfo.value.MailPostalCode;
        this.regInvoiceInfo.value.InvoiceCity = this.regCustomerInfo.value.MailCity;
        this.regInvoiceInfo.value.InvoiceCountry = this.regCustomerInfo.value.MailCountry;
        //this.regInvoiceInfo.value.InvoiceCO = this.regCustomerInfo.value.VisitingCO;
        this.regInvoiceInfo.value.InvoiceAddress2 = this.regCustomerInfo.value.MailAddress2;
        //this.regInvoiceInfo.value.InvoiceRegion = this.regCustomerInfo.value.VisitingRegion;
        
        this.regInvoiceInfo.setControl("InvoiceAddress", new FormControl({value: this.account.MailAddress.AddressLine1, disabled: true}, []));
        this.regInvoiceInfo.setControl("InvoicePostalCode", new FormControl({value: this.account.MailAddress.Postalcode, disabled: true}, []));
        this.regInvoiceInfo.setControl("InvoiceCountry", new FormControl("Norge", []));
        this.regInvoiceInfo.setControl("InvoiceCity", new FormControl({value: this.account.MailAddress.City, disabled: true}, []));
        //this.regInvoiceInfo.setControl("InvoiceCO", new FormControl(null, []));
        this.regInvoiceInfo.setControl("InvoiceAddress2", new FormControl({value: this.account.MailAddress.AddressLine2, disabled: true}, []));
        //this.regInvoiceInfo.setControl("InvoiceRegion", new FormControl(null, []));
        
      } else {
        this.regInvoiceInfo.setControl("InvoiceAddress", new FormControl(null, [Validators.required]));
        this.regInvoiceInfo.setControl("InvoicePostalCode", new FormControl(null, [Validators.required]));
        this.regInvoiceInfo.setControl("InvoiceCountry", new FormControl("Norge", [Validators.required]));
        this.regInvoiceInfo.setControl("InvoiceCity", new FormControl(null, [Validators.required]));
        //this.regInvoiceInfo.setControl("InvoiceCO", new FormControl(null, [Validators.required]));
        this.regInvoiceInfo.setControl("InvoiceAddress2", new FormControl(null, []));
        //this.regInvoiceInfo.setControl("InvoiceRegion", new FormControl(null, [Validators.required]));
      }
    }

  }
  productSpinner: boolean = false;
  products: Product[];
  getProducts(turnover: any) {
    let transportres;
    this.productSpinner = true;
    // console.log("getting products");
    this.productService.getProducts(turnover).subscribe(x => {
      //  console.log(x);
      this.products = x;
      this.products.forEach(function (prod) {
        //    console.log(prod);
        prod.ReasonCodes.forEach(function (reason) {

          if (reason.Name == "Transportmerking") {


            transportres = { Code: reason.Code, Id: reason.Id, Name: reason.Name, label: reason.label };

          }

          //    console.log(reason);
          reason.label = reason.Name;
          reason.value = { Code: reason.Code, Id: reason.Id, Name: reason.Name, label: reason.label };
          //    console.log(reason);
        });
        prod.ReasonCodes = prod.ReasonCodes.filter(x => x.Name != "Transportmerking");

      });

      this.TransportReason = transportres;
      this.productSpinner = false;
    }), error => {
      this.productSpinner = false;
    }, () => {
      //   console.log("Done");
    };


  }

  Reason: string = "Annen";
  selectedProductboxmulti: string = "";
  selectedProductbox: string = "";
  selectedProduct: Product =
    {
      ReasonCodes: null,
      NewPrice: "",
      Price: ""
    };
  selectedReason: Reason;
  TransportReason: Reason;
  setminGtin(val) {
    this.MinGtinVisible = val;
  }
  ChangeProduct(product) {

    this.ReasonOther = false;
    this.regProduct.get('reasonText').setValue("");
    if (product == 1) {
      this.regProduct.setControl('numberOfmeasuringpoints', new FormControl(null, [Validators.required]));
    } else {
      this.regProduct.setControl('numberOfmeasuringpoints', new FormControl(null, []));
    }

    switch (product) {
      case 1: {//veriver
        this.selectedProductboxmulti = "";
        this.selectedProductbox = "601";
        this.selectedProduct = this.products.find(x => x.ProductNumber == this.selectedProductbox);
        this.regProduct.get('selectedProduct').setValue(this.selectedProduct);
        this.SetIsGSRN(true);
        this.regProduct.setControl('gtinudi', new FormControl(null, []));
        this.regProduct.setControl('reason', new FormControl(null, []));
        //console.log(this.products);
        //console.log(this.selectedProduct);
        //get price from crm

        this.productnumber = product;

        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        break;
      }
      case 2: {//GLN =2
        this.selectedProductboxmulti = "";
        //add Reson dropdown and get price from crm
        this.selectedProductbox = "8";
        this.selectedProduct = this.products.find(x => x.ProductNumber == this.selectedProductbox);
        this.regProduct.get('selectedProduct').setValue(this.selectedProduct);
        // console.table(this.selectedProduct.ReasonCodes);
        this.SetIsGTIN8(true);
        this.regProduct.setControl('gtinudi', new FormControl(null, []));
        this.regProduct.setControl('reason', new FormControl(null, [Validators.required]));
        this.productnumber = product;
        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerFocusOut= false;
        break;
      }
      case 3: {//transpomerking
        this.selectedProductboxmulti = "";
        //add Reson dropdown and get price from crm
        this.selectedProductbox = "2";
        this.selectedProduct = this.products.find(x => x.ProductNumber == this.selectedProductbox);
        this.regProduct.get('selectedProduct').setValue(this.selectedProduct);
        this.SetIsTransport();
        //console.log(this.TransportReason);
        this.regProduct.setControl('reason', new FormControl(this.TransportReason, []));
        this.regProduct.setControl('gtinudi', new FormControl(null, []));
        this.productnumber = product;
        //devops 3232 (show kravstiller when Transportmerking is selected)
        this.TransportmerkingReasonCodes = this.selectedProduct.ReasonCodes.filter(p => p.Code == "127");
        this.KrivstillerEnable = true;

        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerValidation = false;
        this.KravstillerFocusOut= false;
        break;
      }
      case 4: {//(GTIN/13/14 )
        this.GtinUDI = null;
        this.Gtin13_14_8_FDADisplay = false;
        if (this.selectedProductbox == "9992" || this.selectedProductbox == "3" || this.selectedProductbox == "12" || this.selectedProductbox == "13") {

        } else {
          this.selectedProduct = null;
          this.selectedProductbox = "999";
          this.selectedProductboxmulti = "9992";
          this.SetIsGTIN13();
        }
        this.regProduct.setControl('reason', new FormControl(null, [Validators.required]));
        this.regProduct.setControl('gtinudi', new FormControl(null, [Validators.required]));
        this.productnumber = product;
        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerFocusOut= false;
        break;
      }
      case 5: { //GTIN/8
        this.Gtin13_14_8_FDADisplay = false;
        this.selectedProductboxmulti = "";
        this.IsProduktmarking = false;
        this.selectedProductbox = "21";
        this.GtinUDI = null;
        this.selectedProduct = this.products.find(x => x.ProductNumber == this.selectedProductbox);
        //devops 3232       
        this.selectedProduct.ReasonCodes = this.getGtin14_13_8_ReasonCodes(this.selectedProduct.ReasonCodes); this.selectedProduct.ReasonCodes = this.getGtin14_13_8_ReasonCodes(this.selectedProduct.ReasonCodes);
        // let indexskalha = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "104");//also remove this
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(indexskalha, 1);
        // let index = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "127");
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(index, 1);
        this.regProduct.get('selectedProduct').setValue(this.selectedProduct);
        this.regProduct.setControl('reason', new FormControl(null, [Validators.required]));
        this.regProduct.setControl('gtinudi', new FormControl(null, [Validators.required]));
        this.productnumber = product;
        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerFocusOut= false;
        break;
      }




      case 22: {//GTIN/13/14
        this.selectedProductbox = "2";
        this.selectedProduct = this.products.find(x => x.ProductNumber == this.selectedProductbox);
        this.selectedProduct.ReasonCodes = this.getGtin14_13_8_ReasonCodes(this.selectedProduct.ReasonCodes);
        //remove transportmerking Products (devops 3232)
        // let indexskalha = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "104");//also remove this
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(0, indexskalha);
        // let index = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "127");
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(0, index);
        this.regProduct.get('selectedProduct').setValue(this.selectedProduct);
        this.selectedProductbox = "9992";
        this.productnumber = product;
        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerFocusOut= false;
        // console.log("over 300");
        break;
      }
      case 33: {//GTIN/13/14
        this.selectedProductbox = "3";
        this.selectedProduct = this.products.find(x => x.ProductNumber == this.selectedProductbox);
        this.regProduct.get('selectedProduct').setValue(this.selectedProduct);
        this.productnumber = product;
        this.selectedProduct.ReasonCodes = this.getGtin14_13_8_ReasonCodes(this.selectedProduct.ReasonCodes);
        // let indexskalha = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "104");//also remove this
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(0, indexskalha);
        // let index = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "127");
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(0, index);
        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerFocusOut= false;
        break;
      }
      case 12: { //GTIN/13/14 
        this.selectedProductbox = "12";
        this.selectedProduct = this.products.find(x => x.ProductNumber == this.selectedProductbox);
        this.regProduct.get('selectedProduct').setValue(this.selectedProduct);
        this.productnumber = product;
        this.selectedProduct.ReasonCodes = this.getGtin14_13_8_ReasonCodes(this.selectedProduct.ReasonCodes);
        // let indexskalha = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "104");//also remove this
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(0, indexskalha);
        // let index = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "127");
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(0, index);
        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerFocusOut= false;
        break;
      }
      case 13: {//GTIN/13/14 
        this.selectedProductbox = "13";
        this.selectedProduct = this.products.find(x => x.ProductNumber == this.selectedProductbox);
        this.regProduct.get('selectedProduct').setValue(this.selectedProduct);
        this.productnumber = product;

        this.selectedProduct.ReasonCodes = this.getGtin14_13_8_ReasonCodes(this.selectedProduct.ReasonCodes);
        // let index = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "127");
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(0, index);
        // let indexskalha = this.selectedProduct.ReasonCodes.findIndex(r => r.Code == "104");//also remove this
        // this.selectedProduct.ReasonCodes = this.selectedProduct.ReasonCodes.splice(0, indexskalha);
        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerFocusOut= false;
        break;
      }

      default: {

        this.selectedProductbox = "0";
        this.productnumber = 0;
        //devops 3232
        this.KrivstillerEnable = false;
        this.KravstillerValidation = false;
        this.TransportmerkingReasonCodes = null;
        this.regProduct.get('Kravstiller').setValue('');
        this.KravstillerFocusOut= false;
        //    console.log("default");
        //   console.log(product);
      }
    }



  }




  getGtin14_13_8_ReasonCodes(reasoncodes) {

    let reasonarr: Reasons[] = [];

    reasoncodes.forEach(function (value) {

      if (value.Code != "104" && value.Code != "127") {
        reasonarr.push(value);
      }

    });

    return reasonarr;
  }
  productBoxCheck() {
    return this.selectedProductbox != '' && this.selectedProductboxmulti == '9992' ||
      this.selectedProductbox != '' && this.selectedProductbox == '3' ||
      this.selectedProductbox != '' && this.selectedProductbox == '12' ||
      this.selectedProductbox != '' && this.selectedProductbox == '13';
  }


  nextsection: boolean = false;
  getSection(event) {

    // console.log(event);
    if (event.selectedIndex == 3) {
      this.getProducts(this.regCustomerInfo.value == null ? this.account.Turnover.Amount : this.regCustomerInfo.get('TurnoverCategory').value);


    }
  }

  ActivateReasonChanged(event) {
    //  console.log(event);
    this.KravstillerFocusOut= false;
    this.ReasonOther = event.value.Name == "Annet med kommentar";
    if (this.ReasonOther) {
      this.regProduct.setControl('reasonText', new FormControl(null, [Validators.required]));
    }
    else {
      this.regProduct.setControl('reasonText', new FormControl(null, []));
    }
    this.selectedReasonId = event.value.Id;
    this.isReason = true;
    this.regProduct.get('Kravstiller').setValue('');
    this.KrivstillerEnable = false;// double check (set when needed)
    this.KravstillerValidation = false;
    console.log(this.selectedProduct.Name + ' - ' + this.productnumber);
    console.log(this.selectedProduct.Name + ' - selectedProductbox - ' + this.selectedProductbox);
    if (this.selectedProductbox == '999' || this.selectedProductbox == '9992'
      || this.selectedProductbox == '3' || this.selectedProductbox == '13'
      || this.selectedProductbox == '12' || this.selectedProductbox == '21') //GTIN13/14 and GTIN8 (selected)
    {
      if (event.value.Code == '113' || event.value.Code == '115' || event.value.Code == '122') {
        this.KrivstillerEnable = true;
        // this.KravstillerValidation = true;
      }
    }
    if (this.selectedProductbox == '8') //GLN selected
    {
      if (event.value.Code == '120' || event.value.Code == '121' || event.value.Code == '123') {
        this.KrivstillerEnable = true;
        // this.KravstillerValidation = true;
      }

    }

  }
  checkdepartpent() {
    this.nextsection = true;
  }
  selectedReasonId: string = "";
  ReasonOther: boolean;

  ValidDDown: boolean = false;
  ValidDropdown() {

    this.ValidDDown = true;
  }
  isReason: boolean = false;
  isNextPagePressed: boolean = false;

  ReasonCheck() {
    if (!this.isReason && this.isNextPagePressed) {
      return true;
    } else {
      return false;
    }
  }

  Nextstep() {
    this.isNextPagePressed = true;
  }

  DisplayKravstiller() {//Devops 3223
    if (!this.KrivstillerEnable)
      return true;
    else
      return false;

  }

  DisplayKravstillerValidation() {//Devops 3223

    var kravstillervalue = this.regProduct.get('Kravstiller');
    if (kravstillervalue != null && kravstillervalue.value.length > 0) {
      this.KravstillerValidation = false;
      return true;
    }
    else {
      if (kravstillervalue.value == '') {
        if (this.KravstillerFocusOut) {
          this.KravstillerValidation = true;
          return false;
        }
        else
          this.KravstillerValidation = false;

        return true;
      }
      else {
        this.KravstillerValidation = true;
      }
      return false;
    }

    if (!this.KravstillerValidation)
      return true;
    else
      return false;

  }
  onTextKravStiller(event) { //Devops 3223

    if (this.regProduct.value != null) {
      if (this.regProduct.value.Kravstiller != null) {
        let txt = this.regProduct.value.Kravstiller;

        if (txt != null) {
          if (txt.length == 0) {
            this.KravstillerValidation = true;
            this.KravstillerFocusOut = true;
          }
          else {
            this.KravstillerValidation = false;
            this.KravstillerFocusOut = false;
          }
        }
      }

    }

  }

  Contactperson: boolean = false;
  ContactpersonSameAsOrderingPerson(event) {
    this.Contactperson = event;
    if (this.Contactperson) {

      this.regCustomerInfo.setControl("RequesterName", new FormControl(null, []));
      this.regCustomerInfo.setControl("RequesterEmail", new FormControl(null, []));
      this.regCustomerInfo.setControl("RequesterPhone", new FormControl(null, []));

      this.regCustomerInfo.value.RequesterPhone = this.regCustomerInfo.value.ContactPhone;
      this.regCustomerInfo.value.RequesterName = this.regCustomerInfo.value.ContactFirstName + " " + this.regCustomerInfo.value.ContactLastName;
      this.regCustomerInfo.value.RequesterEmail = this.regCustomerInfo.value.ContactEmail;


    } else {
      this.regCustomerInfo.value.RequesterName = "";
      this.regCustomerInfo.value.RequesterEmail = "";
      this.regCustomerInfo.value.RequesterPhone = "";
      this.regCustomerInfo.setControl("RequesterName", new FormControl(null, [Validators.required]));
      this.regCustomerInfo.setControl("RequesterEmail", new FormControl(null, [Validators.required, Validators.pattern(this.emailregex)]));
      this.regCustomerInfo.setControl("RequesterPhone", new FormControl(null, [Validators.required]));
    }


  }







}

export interface NewCase {
  AccountOrganizationalnumber: string,
  AccountVisitingAddress: { AddressLine1: string, AddressLine2: string, City: string, Postalcode: string },
  Country_AccountVisitingAddress: string,
  AccountPostalAddress: { AddressLine1: string, AddressLine2: string, City: string, Postalcode: string },
  Country_AccountPostalAddress: string,
  AccountInvoiceAddress: { AddressLine1: string, AddressLine2: string, City: string, Postalcode: string },
  Country_AccountInvoiceAddress: string,
  AccountInvoiceEmail: string,
  AccountTurnoverNOK: string,
  AccountTurnoverYear?: string,
  AccountInvoiceType: number,
  AgreementContactFirstName: string,
  AgreementContactLastName: string,
  AccountEmail: string,
  AccountPhoneNumber: string,
  AgreementContactEmail: string,
  AgreementContactPhoneNumber: string,
  AgreementContactPosition?: string,
  AgreementContactDepartment?: string,
  PurchaseOrder: string,
  IndustryCode: string,
  ProductId: { id: string },
  RequesterName?: string,
  RequesterEmail?: string,
  RequesterPhone?: string,
  ReasonCodeId: { Id: string },
  ReasonText: string,
  Account: CRMAccount,
  NACE_Code?: any[],
  NumberOfmeasuringpoints?: string;
  IsForeignOrganization?: boolean;
  Comments?: string;
  GtinUDI?: boolean;
  MinGtinVisible?: boolean;
  IsManualRegister?: boolean;
  Gtin13Or14Or8FDA?: boolean;
  Kravstiller: string;//devop 3232
}


