import { Component, OnInit } from '@angular/core';
import { CRMAccount, InvoiceType } from '../../models/crmAccount';
import { CrmAccountService } from '../../services/crm-account.service';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Contact } from '../../models/contact';

import { MembershipService, Reason } from '../../services/membership.service';
import { ContactService } from '../../../app/services/contact.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { __values } from 'tslib';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { element } from 'protractor';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-firmaopplysninger',
  templateUrl: './firmaopplysninger.component.html',
  styleUrls: ['./firmaopplysninger.component.css']
})
export class FirmaopplysningerComponent implements OnInit {
  Contact: Contact;
  ContactList: ContactList[] = [];
  PrimaryContactId: ContactList;

  ////devsop 3620
  mailAddressLine1: string; ////devsop 3620 
  mailAddressLine2: string;
  mailCity: string;
  mailCountry: string;
  mailPostalcode: string;


  visitAddressLine1: string; ////devsop 3620
  visitAddressLine2: string;
  visitCity: string;
  visitCountry: string;
  visitPostalcode: string;
  ////devsop 3620

  constructor(private accountService: CrmAccountService, private auth: AuthService, private fb: FormBuilder, private router: Router, private messageService: MessageService, private contactService: ContactService, private membershipService: MembershipService) { }

  ngOnInit() {

    if (this.auth.getUser() == null) {
      this.router.navigate(['/Home']);
    } else {
      this.setAccountinfo();
    }

  }

  setAccountinfo(fetchaccount = false) {
    this.showSpinner = true;
    if ((this.auth.account != null && this.auth.account.Id != undefined) && !fetchaccount) {

      if (this.auth.account.Id != null) {

        this.CRMAccount = this.auth.account;
        this.BillingAddress = this.auth.account.VisitingAddress == this.auth.account.BillingAddress;
        this.VisitingAddress = this.auth.account.MailAddress == this.auth.account.VisitingAddress;
        this.PONummer = this.auth.account.POReferansenummer;
        this.PrimaryContactId = ({ label: (this.auth.account.PrimaryContactId != null) ? this.auth.account.PrimaryContactId.Name : "", value: { Id: (this.auth.account.PrimaryContactId != null) ? this.auth.account.PrimaryContactId.Id : null, Name: (this.auth.account.PrimaryContactId != null ? this.auth.account.PrimaryContactId.Name : "") } });
        if (this.auth.account.InvoiceType == 100) {
          let type = this.FakturaTyper.find(b => b.value == 100);
          type.checked = true;
          this.SelectedItem = 100;

        }
        if (this.auth.account.InvoiceType == 101) {
          let type = this.FakturaTyper.find(b => b.value == 101);
          type.checked = true;
          this.SelectedItem = 101;
        }
        if (this.auth.account.InvoiceType == 102) {
          let type = this.FakturaTyper.find(b => b.value == 102);
          type.checked = true;

        }
        ////////////////////////////////
        //form rules!
        this.AccountForm = this.fb.group({
          Email: [this.CRMAccount.Email, [Validators.email, Validators.required]],
          Telephone: [this.CRMAccount.Telephone, [Validators.required]],
          Websiteurl: [this.CRMAccount.Websiteurl, []],
          InvoiceEmail: [this.CRMAccount.InvoiceEmail, []],
          VisitingAddressCheck: [this.VisitingAddress, []],
          BillingAddressCheck: [this.BillingAddress, []],
          PONummer: [this.PONummer, []],
          PrimaryContactId: [this.PrimaryContactId, []],

          BillingAddress1: [this.CRMAccount.BillingAddress.AddressLine1, [Validators.required]],
          BillingAddress2: [this.CRMAccount.BillingAddress.AddressLine2, []],
          BillingCity: [this.CRMAccount.BillingAddress.City, [Validators.required]],
          BillingPostalcode: [this.CRMAccount.BillingAddress.Postalcode, [Validators.required]],
          BillingCountry: [this.CRMAccount.BillingAddress.Country, [Validators.required]],

          MailAddress1: [{ value: this.CRMAccount.MailAddress.AddressLine1, disabled: true }, [Validators.required]],
          MailAddress2: [{ value: this.CRMAccount.MailAddress.AddressLine2, disabled: true }, []],
          MailCity: [{ value: this.CRMAccount.MailAddress.City, disabled: true }, [Validators.required]],
          MailPostalcode: [{ value: this.CRMAccount.MailAddress.Postalcode, disabled: true }, [Validators.required]],
          MailCountry: [{ value: this.CRMAccount.MailAddress.Country, disabled: true }, [Validators.required]],

          VisitingAddress1: [{ value: this.CRMAccount.VisitingAddress.AddressLine1, disabled: true }, [Validators.required]],
          VisitingAddress2: [{ value: this.CRMAccount.VisitingAddress.AddressLine2, disabled: true }, []],
          VisitingCity: [{ value: this.CRMAccount.VisitingAddress.City, disabled: true }, [Validators.required]],
          VisitingPostalcode: [{ value: this.CRMAccount.VisitingAddress.Postalcode, disabled: true }, [Validators.required]],
          VisitingCountry: [{ value: this.CRMAccount.VisitingAddress.Country, disabled: true }, [Validators.required]],

        });
        // this.AccountForm.valueChanges.subscribe(console.log);
        ////////////////////////////////
        let newContactList = [];
        let defaultcontact = [];

        if (this.auth.account.PrimaryContactId == null)
          defaultcontact.push({ label: "", value: null });
        if (this.auth.Contacts != null && this.auth.Contacts.length > 0) {
          this.auth.Contacts.forEach(element => {

            if (this.auth.account.PrimaryContactId != null && this.auth.account.PrimaryContactId.Id == element.Id) {

              defaultcontact.push({ label: element.Name, value: element });

            }
            else {

              newContactList.push({ label: element.Name, value: element });
            }
          })
        }
        else {
          this.contactService.getAllContacts(this.auth.account.Id).subscribe(x => {
            this.auth.Contacts = x;
            let allContactList = [];
            let newContactList = [];
            let defaultcontact = [];
            x.forEach(function (contact) {

              allContactList.push({ label: contact.Name, value: contact });

            })
            if (this.auth.account.PrimaryContactId == null)
              defaultcontact.push({ label: "", value: null });
            allContactList.forEach(element => {
              if (this.auth.account.PrimaryContactId != null && element.value.Id == this.auth.account.PrimaryContactId.Id) {
                defaultcontact.push({ label: element.label, value: element });

              }
              else {

                newContactList.push({ label: element.label, value: element });
              }


            })
          });

        }


        this.ContactList = defaultcontact.concat(newContactList);
        this.showSpinner = false;
        this.acc = true;


      } else {
        this.showSpinner = false;
        this.acc = false;
      }
    }
    else {
      this.auth.getAccountInfo().subscribe(x => {

        // console.log("GetAccountInfo: response from api");
        //  console.log(x);
        if (!x) {
          //    console.log(x + "no CRMAccount found");
          //this.logout();
        } else {
          //    console.log("GetAccountInfo: Account was found");
          this.auth.account = x;
          this.CRMAccount = this.auth.account;

          this.PONummer = this.auth.account.POReferansenummer;
          this.BillingAddress = this.auth.account.VisitingAddress == this.auth.account.BillingAddress;
          this.VisitingAddress = this.auth.account.MailAddress == this.auth.account.VisitingAddress;
          this.PrimaryContactId = ({ label: (this.auth.account.PrimaryContactId != null) ? this.auth.account.PrimaryContactId.Name : "", value: { Id: (this.auth.account.PrimaryContactId != null) ? this.auth.account.PrimaryContactId.Id : null, Name: (this.auth.account.PrimaryContactId != null ? this.auth.account.PrimaryContactId.Name : "") } });
          if (this.auth.account.InvoiceType == 100) {
            let type = this.FakturaTyper.find(b => b.value == 100);
            type.checked = true;
            this.SelectedItem = 100;

          }
          if (this.auth.account.InvoiceType == 101) {
            let type = this.FakturaTyper.find(b => b.value == 101);
            type.checked = true;
            this.SelectedItem = 101;
          }
          if (this.auth.account.InvoiceType == 102) {
            let type = this.FakturaTyper.find(b => b.value == 102);
            type.checked = true;

          }
          ////////////////////////////////
          //form rules!
          this.AccountForm = this.fb.group({
            Email: [this.CRMAccount.Email, [Validators.email, Validators.required]],
            Telephone: [this.CRMAccount.Telephone, [Validators.required]],
            Websiteurl: [this.CRMAccount.Websiteurl, []],
            InvoiceEmail: [this.CRMAccount.InvoiceEmail, []],
            VisitingAddressCheck: [this.VisitingAddress, []],
            BillingAddressCheck: [this.BillingAddress, []],
            PONummer: [this.PONummer, []],
            PrimaryContactId: [this.PrimaryContactId, []],

            BillingAddress1: [this.CRMAccount.BillingAddress.AddressLine1, [Validators.required]],
            BillingAddress2: [this.CRMAccount.BillingAddress.AddressLine2, []],
            BillingCity: [this.CRMAccount.BillingAddress.City, [Validators.required]],
            BillingPostalcode: [this.CRMAccount.BillingAddress.Postalcode, [Validators.required]],
            BillingCountry: [this.CRMAccount.BillingAddress.Country, [Validators.required]],

            MailAddress1: [{ value: this.CRMAccount.MailAddress.AddressLine1, disabled: true }, [Validators.required]],
            MailAddress2: [{ value: this.CRMAccount.MailAddress.AddressLine2, disabled: true }, []],
            MailCity: [{ value: this.CRMAccount.MailAddress.City, disabled: true }, [Validators.required]],
            MailPostalcode: [{ value: this.CRMAccount.MailAddress.Postalcode, disabled: true }, [Validators.required]],
            MailCountry: [{ value: this.CRMAccount.MailAddress.Country, disabled: true }, [Validators.required]],

            VisitingAddress1: [{ value: this.CRMAccount.VisitingAddress.AddressLine1, disabled: true }, [Validators.required]],
            VisitingAddress2: [{ value: this.CRMAccount.VisitingAddress.AddressLine2, disabled: true }, []],
            VisitingCity: [{ value: this.CRMAccount.VisitingAddress.City, disabled: true }, [Validators.required]],
            VisitingPostalcode: [{ value: this.CRMAccount.VisitingAddress.Postalcode, disabled: true }, [Validators.required]],
            VisitingCountry: [{ value: this.CRMAccount.VisitingAddress.Country, disabled: true }, [Validators.required]],

          });
        }

        if (this.auth.account.Id != null) {
          if (this.auth.Avtaler == null) {
            this.membershipService.getMemberships().subscribe(x => {
              x.forEach(function (memberships) {
                memberships.Status = memberships.Status == "0" ? "Aktiv" : "Inaktiv";
                let date = new Date(memberships.StartDate.toString());
                memberships.ShortStartDateString = date.toLocaleDateString();

                if (memberships.ShortStopDateString != null) {
                  let date = new Date(memberships.StopDate.toString());
                  memberships.ShortStopDateString = date.toLocaleDateString();
                }
                if (memberships.ExpiryDate != null) {
                  let date = new Date(memberships.ExpiryDate.toString());
                  memberships.ShortExpiryDateString = date.toLocaleDateString();
                }
              });
              this.auth.Avtaler = x;

              // console.table(this.Avtaler);

            });
          }


          this.contactService.getAllContacts(x.Id).subscribe(x => {
            this.auth.Contacts = x;
            let allContactList = [];
            let newContactList = [];
            let defaultcontact = [];
            x.forEach(function (contact) {

              allContactList.push({ label: contact.Name, value: contact });

            })
            if (this.auth.account.PrimaryContactId == null)
              defaultcontact.push({ label: "", value: null });
            allContactList.forEach(element => {
              if (this.auth.account.PrimaryContactId != null && element.value.Id == this.auth.account.PrimaryContactId.Id) {
                defaultcontact.push({ label: element.label, value: element });

              }
              else {

                newContactList.push({ label: element.label, value: element });
              }


            })




            this.ContactList = defaultcontact.concat(newContactList);



            // this.AccountForm.valueChanges.subscribe(console.log);
            ////////////////////////////////

            this.showSpinner = false;
            this.acc = true;
          });

        } else {
          this.showSpinner = false;
          this.acc = false;
        }

      });
    }
  }




  AccountForm: FormGroup;
  CRMAccount: CRMAccount = {};
  showSpinner: boolean = true;
  acc: boolean;
  PONummer: string;
  FakturaType: string;
  SelectedItem: number;

  FakturaTyper: any[] = [

    { name: 'EHF', value: 100, checked: false },
    { name: 'E-post', value: 101, chekced: false },
    { name: 'Papir (Tillegg på 50,- kroner)', value: 102, checked: false }

  ];

  VisitingAddress: boolean;
  BillingAddress: boolean;


  billingtypechange(event) {
    this.SelectedItem = event.value;

    if (event.value == 101) {
      this.AccountForm.setControl("InvoiceEmail", new FormControl(null, [Validators.email, Validators.required]));
      this.AccountForm.get("InvoiceEmail").enable();
    } else {
      this.AccountForm.get("InvoiceEmail").disable();
    }

  }
  FillInvoiceMail(event): boolean {
    // console.log(event);
    if (this.SelectedItem == 101) {
      return true;
    }
    return false;
  }

  setPostadress(event: any) {
    // console.table(event);




  }


  setVisitingAddressToSame(event) {
    //  console.log(event);
    this.VisitingAddress = event;
    if (event) {
      //   console.log(this.AccountForm.get('MailAddress1').value);
      this.AccountForm.setControl('VisitingAddress1', new FormControl(this.AccountForm.get('MailAddress1').value, [Validators.required]));
      this.AccountForm.setControl('VisitingAddress2', new FormControl(this.AccountForm.get('MailAddress2').value, []));
      this.AccountForm.setControl('VisitingCity', new FormControl(this.AccountForm.get('MailCity').value, [Validators.required]));
      this.AccountForm.setControl('VisitingPostalcode', new FormControl(this.AccountForm.get('MailPostalcode').value, [Validators.required]));
      this.AccountForm.setControl('VisitingCountry', new FormControl(this.AccountForm.get('MailCountry').value, [Validators.required]));

    }
  }

  setBillingAddressToSame(event) {
    // console.log(event);
    this.BillingAddress = event;
    if (event) {

      this.AccountForm.setControl('BillingAddress1', new FormControl(this.AccountForm.get('MailAddress1').value, [Validators.required]));
      this.AccountForm.setControl('BillingAddress2', new FormControl(this.AccountForm.get('MailAddress2').value, []));
      this.AccountForm.setControl('BillingCity', new FormControl(this.AccountForm.get('MailCity').value, [Validators.required]));
      this.AccountForm.setControl('BillingPostalcode', new FormControl(this.AccountForm.get('MailPostalcode').value, [Validators.required]));
      this.AccountForm.setControl('BillingCountry', new FormControl(this.AccountForm.get('MailCountry').value, [Validators.required]));
    }
  }

  setVistingAndBillingAddresses(form: any) { //devsop 3620

    this.mailAddressLine1 = (!form.MailAddress1) ? this.AccountForm.get('MailAddress1').value : form.MailAddress1;
    this.mailAddressLine2 = (!form.MailAddress2) ? this.AccountForm.get('MailAddress2').value : form.MailAddress2;
    this.mailCity = (!form.MailCity) ? this.AccountForm.get('MailCity').value : form.MailCity;
    this.mailCountry = (!form.MailCountry) ? this.AccountForm.get('MailCountry').value : form.MailCountry;
    this.mailPostalcode = (!form.MailPostalcode) ? this.AccountForm.get('MailPostalcode').value : form.MailPostalcode;


    this.visitAddressLine1 = (!form.VisitingAddress1) ? this.AccountForm.get('VisitingAddress1').value : form.VisitingAddress1;
    this.visitAddressLine2 = (!form.VisitingAddress2) ? this.AccountForm.get('VisitingAddress2').value : form.VisitingAddress2;
    this.visitCity = (!form.VisitingCity) ? this.AccountForm.get('VisitingCity').value : form.VisitingCity;
    this.visitCountry = (!form.VisitingCountry) ? this.AccountForm.get('VisitingCountry').value : form.VisitingCountry;
    this.visitPostalcode = (!form.VisitingPostalcode) ? this.AccountForm.get('VisitingPostalcode').value : form.VisitingPostalcode;

  }

  save(form: any) {
    console.log(this.AccountForm.valid);
    if (this.AccountForm.valid) {
      this.showSpinner = true;
      form.Id = this.auth.account.Id;
      form.InvoiceType = this.SelectedItem;
      form.Name = this.auth.account.Name;
      form.OrganizationNumber = this.auth.account.OrganizationNumber;
      form.AccountNumber = this.auth.account.AccountNumber;
      form.POReferansenummer = form.PONummer;
      form.PrimaryContactId = (form.PrimaryContactId.value != null && form.PrimaryContactId.value.Id != null) ? { Id: form.PrimaryContactId.value.Id, Name: form.PrimaryContactId.value.Name } : null
      // console.log(form);
      this.setVistingAndBillingAddresses(form); //devsop 3620 

      form.BillingAddress = { AddressLine1: form.BillingAddress1, AddressLine2: form.BillingAddress2, City: form.BillingCity, Country: form.BillingCountry, Postalcode: form.BillingPostalcode };
      form.MailAddress = { AddressLine1: this.mailAddressLine1, AddressLine2: this.mailAddressLine2, City: this.mailCity, Country: this.mailCountry, Postalcode: this.mailPostalcode };
      form.VisitingAddress = { AddressLine1: this.visitAddressLine1, AddressLine2: this.visitAddressLine2, City: this.visitCity, Country: this.visitCountry, Postalcode: this.visitPostalcode };

      this.accountService.updateAccount(form).subscribe(x => {
        this.showSpinner = false;
        //  console.log("Success!!!");
        this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Lagring utført!' });
        this.setAccountinfo(true);

      }, error => {
        this.showSpinner = false;
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Lagring feilet' });
      });
    }

  }



}
interface ContactList {
  label?: string;
  value: any;
}
