import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SamtykkerService {
    

  constructor(private httpClient: HttpClient, private User: AuthService) { }


  getSamtykker(Guid: string) {
    //return this.http.get<any>();
    return of({
      samtykker: {
        Personvernsamtykke: true,
        SMS: false,
        Epost: true,
        Telefon: true,
        Other: false
      }
    });   
  }

  getConsent() {
    //api/Contact/Consent//
    //return this.httpClient.get<any>(`https://gs1apidev.azurewebsites.net/api/Contact/Consent/${this.User.account.Id}/`);
    return this.httpClient.get<samtykke[]>(`${environment.Api}/Contact/Consent/${this.User.contact.Id}/`);
  }

  getConsentById(Id) {
    //api/Contact/Consent//
    //return this.httpClient.get<any>(`https://gs1apidev.azurewebsites.net/api/Contact/Consent/${this.User.contact.Id}/`);
    return this.httpClient.get<samtykke[]>(`${environment.Api}/Contact/Consent/${Id}/`);
  }

  putSamtykker(x: any,Id:any): any {
   // console.log(Id);
   // console.log(x.ConsentCode);
    return this.httpClient.put(`${environment.Api}/Contact/Consent/putconsent/${Id}`, x);
    return of(true);

    //return this.httpClient.put<boolean>(``, {
    //  guidid : guidid,
    //  Samtykker : x
    //});

  }



}

interface contact {
  samtykker?: any
}

export interface samtykke {
  Tillat: boolean,
  CodeName: string,
  CodeId: string
 
}
