import { trigger, transition, style, query, group, animateChild, animate, keyframes } from '@angular/animations';


export const slider =
  trigger('routeAnimations', [

    transition('middleLeft => middleRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('middleLeft => innerRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('middleLeft => farRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('middleLeft => innerLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),
    transition('middleLeft => farLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),

    transition('middleRight => middleLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),
    transition('middleRight => innerRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('middleRight => farRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('middleRight => innerLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),
    transition('middleRight => farLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),

    transition('innerLeft => middleRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('innerLeft => innerRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('innerLeft => farRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('innerLeft => middleLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('innerLeft => farLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),

    transition('innerRight => middleRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),
    transition('innerRight => middleLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),
    transition('innerRight => farRight', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),
    transition('innerRight => innerLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),
    transition('innerRight => farLeft', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),

    transition('farLeft => *', [query(':enter, :leave', [style({ position: 'absolute', top: 0, right: 0, width: '100%' })]), query(':enter', [style({ right: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ right: '100%' }))]), query(':enter', [animate('600ms ease', style({ right: '0%' }))])]),]),



    transition('farRight => *', [query(':enter, :leave', [style({ position: 'absolute', top: 0, left: 0, width: '100%' })]), query(':enter', [style({ left: '-100%' })]), group([query(':leave', [animate('600ms ease', style({ left: '100%' }))]), query(':enter', [animate('600ms ease', style({ left: '0%' }))])]),]),

  ]);
