import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Product } from '../models/product';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient, private User: AuthService) { }


  getProducts(revenue) {
    //return this.http.get<any>();

    return this.httpClient.get<Product[]>(`${environment.Api}/product/get/${revenue}`);

  }

  checkOrg(orgNo) {
    return this.httpClient.get<Product[]>(`${environment.Api}/user/exists/${orgNo}`);

  }

  getReasons(type) {
    return this.httpClient.get<Product[]>(`${environment.Api}/Product?Reason=${type}`);

  }


}
