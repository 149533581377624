import { Component, OnInit } from '@angular/core';
import { Membership as Avtale, Membership } from '../../models/membership';
import { MembershipService, Reason } from '../../services/membership.service';
import { Contact } from '../../models/contact';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { ContactService } from '../../services/contact.service';
import { AuthService } from '../../services/auth.service';
import { CrmAccountService } from '../../services/crm-account.service';
import { fadeInContent } from '@angular/material';
import { CRMAccount } from '../../models/CRMAccount';
import { Xliff } from '@angular/compiler';
import { CaseService, Casetype } from '../../services/case.service';
import { DatePipe } from '@angular/common';
import { MessageService, ConfirmationService } from 'primeng/api';
import { error } from 'protractor';


@Component({
  selector: 'app-abonnement',
  templateUrl: './abonnement.component.html',
  styleUrls: ['./abonnement.component.css']
})
export class AbonnementComponent implements OnInit {


  Avtalenr: string;

  displayDialog: boolean;
  displayDeleteDialog: boolean;
  displayChangeContactDialog: boolean;
  displayMoveAccountDialog: boolean;
  displayReactivateDialog: boolean;

  Contacts: Contact[];
  Contact: Contact;
  showSpinner: boolean = true;
  waitMessage: boolean = false;
  ContactList: ContactList[] = [];

  Avtale: Avtale = { ProductNumber: '' };

  selectedAvtale: Avtale;

  newAvtale: boolean;

  Avtaler: Avtale[];

  cols: any[];
  cloneAvtaler: any = {};

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(
    private membershipService: MembershipService,
    private contactService: ContactService,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private accountService: CrmAccountService,
    private caseSevice: CaseService,
    private datepipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit() {


    //create table headers
    //this.cols =
    //  [
    //  { field: "membershipName", header: "Avtalenr." },
    //  { field: "Product", header: "Avtaletype" },
    //  { field: "Contact.Name", header: "Kontaktperson" },
    //  { field: "StartDate", header: "Startdato" },
    //  { field: "StopDate", header: "Sluttdato" },
    //  { field: "Status", header: "Status" },
    //  //{ field: "Statuskode", header: "Statuskode" },
    //  { field: "Requester", header: "Bestiller" },
    //  { field: "Handliner", header: "Handlinger" },
    //    //{ field: "", header: "" }
    //  ];


    //use service to get contacts
    //this.ContactService.getContacts().then(contacts => this.Contacts1 = contacts);
    //this.ContactService.getContacts().then(contacts => this.Contacts2 = contacts);
    //this.Avtaler = [
    //  { Kontaktperson: "Alexander", Contact: {Name:"Alexander"}, Id: "212352151545416", Avtalenr: "1", Avtaletype: "Avtale-test",  Startdato: "11/03/2019", Sluttdato: "11/03/9999", Status: "Aktiv", Statuskode: "1", Bestiller: "Admin", Handliner:"" },
    //  { Kontaktperson: "Stian", Id: "212352151545416", Avtalenr: "2", Avtaletype: "Avtale-test",  Startdato: "11/03/2019", Sluttdato: "11/03/9999", Status: "Aktiv", Statuskode: "1", Bestiller: "Admin",Handliner: "" },
    //  { Kontaktperson: "Gjøran", Id: "212352151545416", Avtalenr: "3", Avtaletype: "Avtale-test",  Startdato: "11/03/2019", Sluttdato: "11/03/9999", Status: "Inaktiv", Statuskode: "1", Bestiller: "Admin", Handliner: "" }
    //]

    this.showSpinner = true;

    if (this.auth.Avtaler != null && this.auth.Avtaler != undefined) {
      this.Avtaler = this.auth.Avtaler;
      if (this.auth.Contacts != null && this.auth.Contacts != undefined) {
        this.Contacts = this.auth.Contacts;
        let newContactList = [];
        this.auth.Contacts.forEach(function (contact) {
          newContactList.push({ label: contact.Name, value: contact });
        });

        this.ContactList = newContactList;
        this.showSpinner = false;
      }
      else {
        this.contactService.getContacts().subscribe(x => {
          this.Contacts = x;
          this.auth.Contacts = x;
          let newContactList = [];
          x.forEach(function (contact) {
            newContactList.push({ label: contact.Name, value: contact });
          });

          this.ContactList = newContactList;
          this.showSpinner = false;
          // console.table(this.ContactList);

        });
      }
    }
    else if (this.auth.account.Id != null) {
      this.membershipService.getMemberships().subscribe(x => {
        let movedAgreements = (localStorage.getItem("listofMovedAgreement") != null) ? JSON.parse(localStorage.getItem("listofMovedAgreement")) : new Array<string>();
        x.forEach(function (memberships) {

          memberships.Status = memberships.Status == "0" ? "Aktiv" : "Inaktiv";
          memberships.ProductGroup = memberships.PorductGroup == 3 ? "GLN" : memberships.PorductGroup == 2 ? "Målepunktserie" : memberships.PorductGroup == 1 ? "GS1 Foretaksprefiks" : "";
          if (movedAgreements != null && movedAgreements.length > 0) {
            movedAgreements.forEach(item => {
              if (item == memberships.Id) {
                memberships.Status = memberships.Status + " (Sak mottatt)";
              }

            });
          }
          let date = new Date(memberships.StartDate.toString());
          memberships.ShortStartDateString = date.toLocaleDateString();

          if (memberships.ShortStopDateString != null) {
            let date = new Date(memberships.StopDate.toString());
            memberships.ShortStopDateString = date.toLocaleDateString();
          }
          if (memberships.ExpiryDate != null) {
            let date = new Date(memberships.ExpiryDate.toString());
            memberships.ShortExpiryDateString = date.toLocaleDateString();
          }
        });
        this.auth.Avtaler = x;
        this.Avtaler = x;
        // console.table(this.Avtaler);

      });

      this.contactService.getContacts().subscribe(x => {
        this.Contacts = x;
        this.auth.Contacts = x;
        let newContactList = [];
        x.forEach(function (contact) {
          newContactList.push({ label: contact.Name, value: contact });
        });

        this.ContactList = newContactList;
        this.showSpinner = false;
        // console.table(this.ContactList);

      });
    }
    else {
      this.auth.getAccountInfo().subscribe(x => {
        this.auth.account = x;

        this.membershipService.getMemberships().subscribe(x => {
          let movedAgreements = (localStorage.getItem("listofMovedAgreement") != null) ? JSON.parse(localStorage.getItem("listofMovedAgreement")) : new Array<string>();
          x.forEach(function (memberships) {

            memberships.Status = memberships.Status == "0" ? "Aktiv" : "Inaktiv";
            memberships.ProductGroup = memberships.PorductGroup == 3 ? "GLN" : memberships.PorductGroup == 2 ? "Målepunktserie" : memberships.PorductGroup == 1 ? "GS1 Foretaksprefiks" : "";
            //creating vektvareprefiks based on product Number
            if (memberships.ProductNumber) {
              // 11- 2-sifret, 12- 3sifret, 13- 4-sifret
              if (memberships.ProductNumber == "11" || memberships.ProductNumber == "12" || memberships.ProductNumber == "13")
                memberships.ProductGroup = 'GS1 Vektvareprefiks';
            }
            if (movedAgreements != null && movedAgreements.length > 0) {
              movedAgreements.forEach(item => {
                if (item == memberships.Id) {
                  memberships.Status = memberships.Status + " (Sak mottatt)";
                }

              });
            }
            let date = new Date(memberships.StartDate.toString());
            memberships.ShortStartDateString = date.toLocaleDateString();

            if (memberships.ShortStopDateString != null) {
              let date = new Date(memberships.StopDate.toString());
              memberships.ShortStopDateString = date.toLocaleDateString();
            }
            if (memberships.ExpiryDate != null) {
              let date = new Date(memberships.ExpiryDate.toString());
              memberships.ShortExpiryDateString = date.toLocaleDateString();
            }
          });
          this.auth.Avtaler = x;
          this.Avtaler = x;
          // console.table(this.Avtaler);

        });


        this.contactService.getContacts().subscribe(x => {
          this.Contacts = x;
          this.auth.Contacts = x;
          let newContactList = [];
          x.forEach(function (contact) {
            newContactList.push({ label: contact.Name, value: contact });
          });

          this.ContactList = newContactList;
          this.showSpinner = false;
          // console.table(this.ContactList);

        });

      });
    }
    this.getDeleteReasons();

  }

  showDialogToAdd() {
    this.newAvtale = true;
    this.Avtale = {};
    this.displayDialog = true;
  }

  showDialogToDelete() {
    this.newAvtale = true;
    this.Avtale = {};
    this.displayDialog = true;
  }

  showDialogToEdit() {
    this.newAvtale = true;
    this.Avtale = {};
    this.displayDialog = true;
  }

  showDialogToMove() {
    this.newAvtale = true;
    this.Avtale = {};
    this.displayDialog = true;
  }


  save() {
    let Avtaler = [...this.Avtaler];
    if (this.newAvtale)
      Avtaler.push(this.Avtale);
    else
      Avtaler[this.Avtaler.indexOf(this.selectedAvtale)] = this.Avtale;

    this.Avtaler = Avtaler;
    this.Avtale = null;
    this.displayDialog = false;
  }



  saveContactChange() {
    // console.log("in saveContactChange");
    // console.log(this.Contact);
    // console.log(this.Avtale);
    let membership: Membership = {
      Id: this.Avtale.Id, Contact: { Id: this.Contact.Id }
    }

    this.membershipService.ChangeMembershipContact(membership).subscribe(
      Sucess => {
        if (Sucess) {
          this.Avtale.Contact = this.Contact;
          this.removeDialogs();
          location.reload();
        } else {
          // console.log("Fail");
        }
      },
      error => {
        // console.log(error);
        // console.log("FatalError");

      });



  }



  Reactivate() {
    this.isAttemtSave = true;

    //if (this.Contact == null || this.SignupReason != null && this.Reason == null) {
    //  this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Velg kontakt og årsak' });
    //} else { 

    this.confirmationService.confirm({
      message: 'Godtar du avtalevilkårne for denne avtalen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nei',
      accept: () => {
        //Actual logic to perform a confirmation
        this.showSpinner = true;
        this.waitMessage = true;
        this.activate();
      }, reject: () => {

      }


    });
    //}


  }

  SignupReason: Reasons[];
  DeleteReason: Reasons[];
  DeleteReasonTemp: Reasons[];//DEvops 3232
  getSignupReasons(id: any) {
    let newSignupReasons = [];

    this.membershipService.getregistrationreasoncodes(id).subscribe(x => {
      x.forEach(function (x) {
        newSignupReasons.push({ label: x.Name, value: x });
      });
      this.SignupReason = newSignupReasons;
      // console.table(this.SignupReason);
      return this.SignupReason;
    });

  }

  getDeleteReasons(): Reasons[] {
    if (this.DeleteReason != null) {
      return this.DeleteReason;
    } else {
      let newDeleteReasons = [];
      this.membershipService.getcancellationreasoncodes().subscribe(x => {
        x.forEach(function (x) {
          newDeleteReasons.push({ label: x.Name, value: x });
        });
        this.DeleteReason = newDeleteReasons;
        // console.table(this.DeleteReason);
        return this.DeleteReason;
      });

    }
  }


  reasonChoosed: boolean = false;
  deleteclicked: boolean = false;
  ReasonChange() {
    this.reasonChoosed = true;
    let dynItem: any;//devops 3232
    // console.log(this.Reason);
    if (this.Reason.Code == '107') {//fortaks prefiks annen
      this.isReason = true;
    } else {

      if (this.Reason.hasOwnProperty('value')) { //GLN product annen
        dynItem = this.Reason;// grab dynamic object
        if (dynItem.value.Code = "107" && dynItem.value.Name == "Annen årsak") {
          this.isReason = true;
        }
        else
          this.isReason = false;
      }
      else
        this.isReason = false;
    }
  }

  isReason: boolean = false;
  Reason: Reason = { ReasonText: "" };
  ReasonText: any = "";
  isAttemtSave: boolean = false;
  isChoosed: boolean = false;
  isChoosedContact: boolean = false;
  ActivateReasonChanged() {
    this.isChoosed = true;
    // console.log(this.Reason.Code);
    if (this.Reason.Code == '122' || this.Reason.Code == '123') {
      this.isReason = true;
    } else {
      this.isReason = false;
    }
  }

  termination: Casetype;
  transfer: Casetype;
  reactivate: Casetype;
  delete() {
    this.deleteclicked = true;
    if (this.reasonChoosed) {
      this.showSpinner = true;
      this.waitMessage = true;

      //this.displayDeleteDialog = true;
      let index = this.Avtaler.indexOf(this.Avtale);
      //console.log(this.Reason);
      let selectedreason: any;//dynamic object //evops 3232
      let selectedreasonId: any;
      if (this.Reason.hasOwnProperty('value')) {
        selectedreason = this.Reason;
        selectedreasonId = selectedreason.value.Id;
      }
      else {
        selectedreasonId = this.Reason.Id;
      }

      this.termination = { accountId: this.auth.account.Id, membershipId: this.Avtale.Id, reasonId: selectedreasonId, requesterEmail: this.auth.getUserEmail(), reasonText: this.Reason.ReasonText == null ? "" : this.Reason.ReasonText }
      localStorage.setItem("AvtaleId", this.Avtale.Id);

      this.caseSevice.TerminateMembership(this.termination).subscribe(x => {
        //made case confirmed
        this.ReasonText = "";
        this.Reason = { ReasonText: "" };
        this.deleteclicked = false;
        this.reasonChoosed = false;
        let movedAgreements = (localStorage.getItem("listofMovedAgreement") != null) ? JSON.parse(localStorage.getItem("listofMovedAgreement")) : new Array<string>();
        if (localStorage.getItem("AvtaleId") != null) {
          movedAgreements.push(localStorage.getItem("AvtaleId"));

          localStorage.setItem("listofMovedAgreement", JSON.stringify(movedAgreements));
        }


        this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Sak vil bli opprettet og behandles av medlemsservice' });
        localStorage.removeItem("AvtaleId");
        location.reload();

      }, error => {
        localStorage.removeItem("AvtaleId");
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'avslutting av avtale feilet. Prøv igjen eller ta kontakt med medlemsservice.' });

      });


      //this.Avtaler[index].Status = "Inaktiv";
      //this.Avtaler = this.Avtaler.filter((val, i) => i != index);

      this.Avtale = { ProductNumber: '' };
      this.removeDialogs();
    }
    //connect to crm and make membership inactive? (this needs to be confirmed before its showed in the portal so how should we show that the user had made a request to change the membership status)
  }

  saveMembershipOwnerChange() {
    // this.movedAgreements = new Array<string>();
    this.transfer = { accountId: this.Org.Id, membershipId: this.Avtale.Id, requesterEmail: this.auth.getUserEmail() }
    this.caseSevice.TransferMembership(this.transfer).subscribe(x => {
      //made case confirmed
      // console.log(x);
      // console.log("success transfer of membership");
      let movedAgreements = (localStorage.getItem("listofMovedAgreement") != null) ? JSON.parse(localStorage.getItem("listofMovedAgreement")) : new Array<string>();
      this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Sak vil bli opprettet og behandles av medlemsservice' });
      movedAgreements.push(this.Avtale.Id);
      localStorage.setItem("listofMovedAgreement", JSON.stringify(movedAgreements));
      location.reload()



    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Overførsel av avtale feilet. Prøv igjen eller ta kontakt med medlemsservice.' });

    });

    this.removeDialogs();

  }


  activate() {
    //let index = this.Avtaler.indexOf(this.selectedAvtale);
    //this.Avtaler[index].Status = "Aktiv";
    if (this.Reason == null) {
      this.reactivate = { accountId: this.auth.account.Id, membershipId: this.Avtale.Id, reasonId: null, requesterEmail: this.auth.getUserEmail(), reasonText: null, contactId: null }

    } else {
      this.reactivate = { accountId: this.auth.account.Id, membershipId: this.Avtale.Id, reasonId: this.Reason.Id, requesterEmail: this.auth.getUserEmail(), reasonText: this.Reason.ReasonText, contactId: null }

    }
    this.caseSevice.ReactivateMembership(this.reactivate).subscribe(x => {
      //made case confirmed

      this.messageService.add({ severity: 'success', summary: 'Complete', detail: 'Sak vil bli opprettet og behandles av medlemsservice' });
      location.reload();
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Feil ved reaktivering. Prøv igjen eller ta kontakt med medlemsservice.' });

    });
    if (this.Reason != null) {
      this.Reason.ReasonText = "";
    }
    this.removeDialogs();
    this.isAttemtSave = false;
    this.isChoosed = false;
    this.isChoosedContact = false;
    //connect to crm and make membership inactive? (this needs to be confirmed before its showed in the portal so how should we show that the user had made a request to change the membership status)

  }


  onRowSelect(event: Avtale) {
    this.newAvtale = false;

    this.Avtalenr = event.membershipNameLand;

    this.Avtale = this.cloneAvtale(event);
    this.displayDialog = true;
  }


  cloneAvtale(c: Avtale): Avtale {
    let Avtale = {};
    for (let prop in c) {
      Avtale[prop] = c[prop];
    }
    return Avtale;
  }

  StatusCheck(rowData): boolean {
    return rowData.Status == "Aktiv" || rowData.Status == "Aktiv (Sak mottatt)";
  }



  DisplayDelDialog(event: Avtale) {
    this.DeleteReasonTemp = [];
    if (event.Product == 'GLN Årsavgift') {
      for (let i = 0; i < this.DeleteReason.length; i++) {
        if (!this.DeleteReason[i].value.Name.includes('GTIN')) {
          this.DeleteReasonTemp.push({ label: this.DeleteReason[i].value.Name, value: this.DeleteReason[i] });
        }
      }

    } else {
      this.DeleteReasonTemp = this.DeleteReason;
    }
    this.Avtale = event;
    // console.log(event);
    this.Avtalenr = event.membershipNameLand;
    this.displayDeleteDialog = true;
  }

  displayContactChangeDialog(event: Avtale) {
    this.Avtalenr = event.membershipNameLand;
    this.Avtale = event;
    this.displayChangeContactDialog = true;
  }

  displayMoveAccDialog(event: Avtale) {
    this.Avtalenr = event.membershipNameLand;
    this.Avtale = event;
    this.displayMoveAccountDialog = true;
  }

  displayactivateDialog(event: Avtale) {
    this.getSignupReasons(event.Id);
    this.Avtale = event;

    this.Avtalenr = event.membershipNameLand;
    this.displayReactivateDialog = true;
  }

  isGLN() {
    return this.Avtale.ProductNumber == '8';
  }

  removeDialogs() {
    this.displayDeleteDialog = false;
    this.displayDialog = false;
    this.displayChangeContactDialog = false;
    this.displayMoveAccountDialog = false;
    this.displayReactivateDialog = false;
    this.Reason = { ReasonText: "" };
    this.isChoosed = false;
    this.isChoosedContact = false
    this.isAttemtSave = false;
    this.isReason = false;
  }

  orgnr: string;
  OrgFound: boolean = false;
  Org: CRMAccount;
  OrgName: string;
  ErrorMes: string = "";
  CheckOrg() {
    this.ErrorMes = '';
    if (this.orgnr != null) {
      this.orgnr = this.orgnr.split(" ").join("");
    }

    if (this.orgnr != null && this.orgnr.length >= 9) {

      this.accountService.CheckAccountWithOrgNr(this.orgnr).subscribe(x => {
        //  console.log("i made it!");
        this.accountService.getAccountWithOrgNr(this.orgnr).subscribe(x => {
          //  console.log(x);
          this.OrgFound = true;
          this.Org = x;
          this.OrgName = this.Org.Name;
          //   console.log(this.OrgName + " : org navn her!");
          this.ErrorMes = '';
        });
      }, error => {
        //   console.log("error was found :(");
        this.OrgFound = false;
        this.ErrorMes = "Dette org. nummeret er ikke registrert hos GS1 Norway. Ta kontakt med vår medlemsservice."
      });

    }
    else {
      this.OrgFound = false;
    }

  }


}





interface ContactList {
  label?: string;
  value: any;
}

export interface Reasons {
  label?: string;
  value: Reason;
  Name?: string;
  Id?: string;
  Code?: string;
}


interface SelectItem {
  label?: string;
  value: any;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}



