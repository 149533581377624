import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})


export class NavMenuComponent implements OnInit {
IsAuthenticated:boolean;
IsMinGtin:boolean;
Imageinfo={"id":1,"type":"GTIN","url":environment.MineGtin,"src":"assets/image/Gtin_barcode.png"};
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService) { }

  ngOnInit() {
   // this.isHandset$.subscribe(isHandset => console.log(isHandset));

    if (localStorage.getItem("ColAccess") != null) {     
      this.authService.HoldUser = false;
    }   
    
  }

  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  checkUser(): boolean {
    //console.log("logged inn? "+ this.authService.getUser());
    this.IsAuthenticated= false;
    var isauth=localStorage.getItem("IsAuthenticated");
    
    if(isauth!=null&&isauth=="true")
    {
      this.IsAuthenticated=true;
    }
    if(localStorage.getItem("MinGTIN")!=null)
    {
      this.IsMinGtin=(localStorage.getItem("MinGTIN")=="true");
    }
    return (this.authService.isLoggedIn());
  }

}
