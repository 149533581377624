import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../models/contact';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { CRMAccount } from '../models/CRMAccount';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })

};
@Injectable({
  providedIn: 'root'
})
export class CaseService {

  constructor(private httpClient: HttpClient) { }
  

  TerminateMembership(termination: Casetype) {
    return this.httpClient.post<boolean>(`${environment.Api}/case/terminate/`, termination, httpOptions);
  }

  TransferMembership(transfer: Casetype) {
    return this.httpClient.post<boolean>(`${environment.Api}/case/transfer/`, transfer, httpOptions);
  }


  ReactivateMembership(reactivate: Casetype) {
    return this.httpClient.post<boolean>(`${environment.Api}/case/reactivate/`, reactivate, httpOptions);
  }

  createCase(account: any) {
   // console.log("Post: Create Case");
    return this.httpClient.post<any>(`${environment.Api}/case/post`, account, httpOptions);
  }


}



export interface Casetype {
  membershipId?: string,
  accountId?: string,
  reasonId?: string,
  reasonText?: string,
  requesterEmail?: string,
  contactId?: string
  
}
