import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';
import { SamtykkerService, samtykke } from '../../services/samtykker.service';
import { AuthService } from '../../services/auth.service';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-samtykker',
  templateUrl: './samtykker.component.html',
  styleUrls: ['./samtykker.component.css']
})
export class SamtykkerComponent implements OnInit {

  
  ConcentForm: FormGroup;
  guidid: string = "gsdfkj";
  loadingSpinner: Boolean = true;
  consents: samtykke[] = [];
  consent: string;
  constructor(private samtykkerService: SamtykkerService, private fb:FormBuilder, private contact:ContactService, private auth:AuthService) { }

  ngOnInit() {
    this.setconsent();
    //this.ConcentForm = this.fb.group({
    //  Samtykker: ["",[]]
    //});

    //this.getSamtykker("test");

    


    //this.ConcentForm.get('Samtykker').valueChanges.subscribe(x => {
    //  console.log(x);
    //  this.samtykkerService.putSamtykker(this.guidid, x).subscribe(x => {

    //  });
    //});
  }

  setconsent() {
    this.auth.getAccountInfo().subscribe(x => {
      if (!x) {
        //this.logout();
      } else {
        this.auth.account = x;
        this.contact.getContactByMail(this.auth.getUserEmail()).subscribe(x => {
          sessionStorage.setItem(this.auth.getUserEmail(), x.Id.toString());
          this.samtykkerService.getConsentById(x.Id).subscribe(x => {
           // console.table(x);
            this.consents = x;
          //  console.log(this.consents[0].CodeName);
         //  console.log(this.consents);
            this.loadingSpinner = false;
          });

        });
      }
    });


  }


  SaveChange() {
    this.loadingSpinner = true;
    let id = sessionStorage.getItem(this.auth.getUserEmail());
    
    this.samtykkerService.putSamtykker(this.consents, id).subscribe(x => {
     // console.log("Done");
      this.loadingSpinner = false;
    });
  }






}
