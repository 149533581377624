import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Membership } from '../models/membership'
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(private httpClient: HttpClient, private auth: AuthService) { }


  getMemberships() {
    //return this.http.get<any>();

    return this.httpClient.get<Membership[]>(`${environment.Api}/Membership?AccountId=${this.auth.account.Id}`);

  }
  getMembershipsById(Id) {
    //return this.http.get<any>();

    return this.httpClient.get<Membership[]>(`${environment.Api}/Membership?AccountId=${Id}`);

  }
  ChangeMembershipContact(membership:Membership) {
    //return this.http.get<any>();

    return this.httpClient.post<boolean>(`${environment.Api}/membership/put`, membership);

  }


  getregistrationreasoncodes(Id) {
    return this.httpClient.get<Reason[]>(`${environment.Api}/membership/getregistrationreasoncodes/${Id}`);
  }

  getcancellationreasoncodes() {
    return this.httpClient.get<Reason[]>(`${environment.Api}/membership/getcancellationreasoncodes`);
  }

 
}



export interface Reason {
  Code?: string;
  ProductGroup?: any;
  Type?: any;
  ToBeDeactivated?: any;
  Name?: any;
  Id?: any;
  LogicalName?: any;
  ReasonText?: any;
  label?: string;
}
