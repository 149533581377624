import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../models/contact';
import { Observable,of } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',   
  })
  
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpClient: HttpClient, private User: AuthService) { }

  

  getContacts() {
     
    return this.httpClient.get<Contact[]>(`${environment.Api}/Contact/${this.User.account.Id}/`);

  }
  getAllContacts(Id) {

    return this.httpClient.get<Contact[]>(`${environment.Api}/Contact/${Id}/`);

  }


  disableContact(contact: Contact) {

    return this.httpClient.put<boolean>(`${environment.Api}/Contact/Deactivate`, contact, httpOptions);
  }

  saveContact(contact: Contact) {
    return this.httpClient.put<boolean>(`${environment.Api}/Contact/`, contact, httpOptions);
  }

  createContact(contact: Contact) {
    return this.httpClient.post<boolean>(`${environment.Api}/Contact/${this.User.account.Id}/`, contact, httpOptions);
  }
  createNewContact(contact: Contact, Id) {
    return this.httpClient.post<boolean>(`${environment.Api}/Contact/${Id}/`, contact, httpOptions);
  }

  getContact() {
    return this.httpClient.get<Contact>(`${environment.Api}/getcontactbymail/${this.User.AccountMail}/`);
  }

  getContactByMail(Mail) {
    return this.httpClient.get<Contact>(`${environment.Api}/getcontactbymail/${Mail}/`);
  }

}

//interface brregOrg {

//  organisasjonsnummer: string,
//  navn: string,
//  registreringsdatoEnhetsregisteret: string,
//  antallAnsatte: string

//}



//interface Contact {
//  Name: string;
//  Email: string;
//  Phone: string;
//  Title: string;
//  Department: string;
//  InAgreement: string;
//}
