import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, tap} from 'rxjs/operators';
import {  throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin':'*',
    // Authorization: `Bearer bhYw30Dn57ERK-shmcN2BVqM5AK6iJiRW1LSRMbbggwtUTeyDQdtd2oQDY664CGUNyI7b522kljsH4dK25qK9ou5AvL2NB2UnvyBOMs-ITnOnEQCsz9-3xmMpwPE8u_cJRf8XeXIDJw_JGbGLSHc5KgMG2xu0JU8bF_uLemaiv1Dm3INZF9YDdLYsBWBPIQ7jR6ZgrikmrDLVJ8bHaVTtT9cwMHW-8oeuvzSC6JTVTXT26xt1Ww8P1__AXQyg6bveVhX4Tsow5SPdmepgEHcrFQS1XsU488kmK5l__zgzDxC-rA8YtsILvbeE0PsF1Pde-sP9T43_2iR3K43oLU6qt4ws4TwHlHiQBk8R4-VJSoi4bvjRlR3hbP_h2z_MzX6GU31F0P7vFDceKFslrG-iQ4Kl6khJfVkJbUslgj52y03AVxHIFn4SWPX31bRm9tyadoSNQ`   
  })
  
};

@Injectable({
  providedIn: 'root'
})

export class ReskontoService {

  constructor(private httpClient: HttpClient) { }

  getPOAccessToken() {
     
    return this.httpClient.get<any>(`${environment.Api}/poweroffice/GetPowerOfficeAPIToken/`,httpOptions).pipe(
        catchError((error: HttpErrorResponse) => {
        return throwError(`Error in retreiving getPOAccessToken. ${error.statusText || 'Unknown'} `);
        }));;

  }

  getPOCustomerByName(customerName) {
     
    return this.httpClient.get<any>(`${environment.Api}/poweroffice/GetPOCustomerByName/${customerName}/`,httpOptions).pipe(
        catchError((error: HttpErrorResponse) => {
        return throwError(`Error in retreiving getPOCustomerByName. ${error.statusText || 'Unknown'} `);
        }));;

  }
  getPOCustomerCode(pogoId) {
     
    return this.httpClient.get<any>(`${environment.Api}/poweroffice/GetPOCustomerByID/${pogoId}/`,httpOptions).pipe(
        catchError((error: HttpErrorResponse) => {
        return throwError(`Error in retreiving getPOCustomerByName. ${error.statusText || 'Unknown'} `);
        }));;

  }
  getPOCustomerLedger(customerCode) {

    return this.httpClient.get<any>(`${environment.Api}/poweroffice/GetPOCustomerLedger/${customerCode}/`).pipe(
        catchError((error: HttpErrorResponse) => {
        return throwError(`Error in retreiving getPOCustomerLedger. ${error.statusText || 'Unknown'} `);
        }));;

  }
  getVouvherPdf(voucherNo)
  {
    return this.httpClient.get(`${environment.Api}/poweroffice/GetVoucherPdf/${voucherNo}/`,
    { responseType: 'blob' })
    .pipe(
      tap(

        // Log the result or error
        data => console.log('You received data'),
        error => console.log(error)
      ),catchError((error: HttpErrorResponse) => {
        return throwError(`Error in retreiving getVouvherPdf. ${error.statusText || 'Unknown'} `);
        })
    );
  }


}

//interface brregOrg {

//  organisasjonsnummer: string,
//  navn: string,
//  registreringsdatoEnhetsregisteret: string,
//  antallAnsatte: string

//}



//interface Contact {
//  Name: string;
//  Email: string;
//  Phone: string;
//  Title: string;
//  Department: string;
//  InAgreement: string;
//}
